import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { InputText, SecondaryButton, SecondaryCheckbox, DatePicker } from '../../../../../Core/Controls';
import SignaturePad from 'react-signature-canvas';
// import './defect.scss';
import { CustomIconButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import ClearIcon from '@material-ui/icons/Clear';
import { postTechnicianSignOff } from '../../../../../Core/Services/InternalUser/Maintenance/DefectBreackDownStepper/DefectBreackDownStepper';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { Tooltip } from '@material-ui/core';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { setCompletionDate } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

const SignOff = (props) => {
    const [state, setState] = useState({
        signedOffByName: props.displayName,
        errors: {},
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        signOffNotes: props.jobDetails.signOffNotes || '',
        signedOffByName: props.jobDetails.signedOffByName || '',

        signOffSignature: props.jobDetails.signOffSignature || '',
        isDisabled: props.jobDetails.signOffSignature ? true : false
    });
    const { showToastSuccess, showToastError } = useToast();
    const signCanvas = useRef({});
    const { isLoggedIn, displayName, lastLogin, userRoles } = useLoginInfo();
    const isInternalUser = userRoles.includes('internal');

    // useEffect(() => {
    //     if (props.jobData.vehicleJobProgressTechnicianSignOffSignature) {
    //         setState((st) => ({
    //             ...st,
    //             isDisabled: true,
    //             imgtechniciansign: true,
    //             signOffSignature : props.jobData.vehicleJobProgressTechnicianSignOffSignature,
    //             signOffNotes : props.jobData.vehicleJobProgressSignOffNotes
    //         }));
    //     }
    // }, []);

    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: false,
                signOffSignature: null
            }));
        } else {
            signCanvas.current.clear();
            setState((st) => ({
                ...st,
                signOffSignature: null
            }));
        }
    };

    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                signOffSignature: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };
    const InputChangeHandler = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const validations = () => {
        const { signOffNotes, signedOffByName, signOffSignature } = state;
        let formIsValid = true;
        let errors = {};
        if (!signOffNotes) {
            errors.signOffNotes = 'This field is required';
            formIsValid = false;
        }
        if (!signedOffByName) {
            errors.signedOffByName = 'This field is required';
            formIsValid = false;
        }
        if (!signOffSignature) {
            errors.signOffSignature = 'This field is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            let FormData = {
                jobID: props.jobID,
                jobCompletionDate: props.jobCompletionDate,
                signOffNotes: state.signOffNotes,
                signedOffByName: state.signedOffByName,
                signOffSignature: state.signOffSignature
            };
            let res = await setCompletionDate(FormData);
            if (res.success) {
                showToastSuccess(`Technician sign off added successfully`);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };
    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgtechniciansign: true, signOffSignature: AppStorage.getSignature() }));
    };

    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel style={{ fontWeight: 'bold' }}>{props.jobData.defect}</InputLabel>
            </Grid> */}
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel style={{ fontWeight: 'bold' }}>Declaration</InputLabel>
                <Typography variant="body2">{props.declaration}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink>Comment</InputLabel>
                <InputText
                    multiline
                    rows={3}
                    placeholder="Comment"
                    value={state.signOffNotes}
                    onChange={InputChangeHandler}
                    name="signOffNotes"
                    disabled={state.isDisabled}
                />
                <FormHelperText error>{state.errors.signOffNotes}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink>Name</InputLabel>
                <InputText
                    placeholder="Comment"
                    value={state.signedOffByName}
                    onChange={InputChangeHandler}
                    name="signedOffByName "
                    disabled={state.isDisabled}
                />
                <FormHelperText error>{state.errors.signedOffByName}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <div style={{ position: 'relative' }}>
                    <InputLabel
                        shrink
                        component="legend"
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        Signature
                        {state.signAvailable ? (
                            <Tooltip title="Use default signature">
                                <BorderColorIcon onClick={useDefaultSig} style={{ margin: '0px 0px -5px 5px' }} />
                            </Tooltip>
                        ) : null}
                    </InputLabel>

                    <div
                        style={{
                            position: 'absolute',
                            right: 0
                        }}
                    >
                        {!state.isDisabled && (
                            <Tooltip title="Clear Signature">
                                <DeleteIcon onClick={clear} />
                            </Tooltip>
                        )}
                    </div>

                    {props.jobDetails.signOffSignature ? (
                        <img
                            src={state.signOffSignature}
                            alt="my signature"
                            style={{
                                display: 'block',
                                margin: '0 auto',
                                border: '1px solid black',
                                width: '100%',
                                height: '165px'
                            }}
                        />
                    ) : (
                        <div className="sigContainer">
                            <SignaturePad
                                canvasProps={{ className: 'sigPad' }}
                                ref={signCanvas}
                                onEnd={() => {
                                    GetTechnicianSign();
                                }}
                            />
                        </div>
                    )}

                    <FormHelperText error>{state.errors.signOffSignature}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler} disabled={state.isDisabled}>
                    Sign off
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default SignOff;
