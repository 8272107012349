import React, { useState, useEffect, useMemo } from 'react';
import { createInvoice } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryButton, DatePicker, MandatoryField, SecondaryCheckbox } from '../../../../../../Core/Controls';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { some } from 'lodash';
import moment from 'moment';
import { GetPurchaseInvoice } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
function PurchaseInvoice(props) {
    const [state, setState] = useState({
        jobItemsList: [],
        loader: true,
        open: false,
        jobStatusID: '',
        purchaseInvoiceDate: moment().format('YYYY-DD-MM'),
        purchaseInvoiceDateDue: '',
        purchaseNominalCode: '',
        invoiceLines: [],
        purchaseInvoiceNumber: '',
        errors: {},
        showOk: true
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res1 = await GetPurchaseInvoice(props.scheduleJobID);
        if (res1.success) {
            setState((st) => ({
                ...st,
                vatRate: res1.data.vatRate,
                vehicleDetails: res1.data?.vehicleDetails || [],
                invoiceLines: res1.data.invoiceLines.map((m) => ({ ...m, invoiceItemVATRateID: null, invoiceItemVAT: null, isSelected: true })) || [],
                partsDetails: res1.data.partsDetails.map((m) => ({ ...m, invoiceItemVATRateID: null, invoiceItemVAT: null, isSelected: true }))
            }));
        }
    }, []);

    useEffect(async () => {
        console.log(state.invoiceLines);
    }, [state.invoiceLines]);

    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(() => {
        let getSelectedJob = some(state.invoiceLines, (r) => r.isSelected);
        setState((state) => {
            const nst = { ...state, showOk: getSelectedJob };
            return nst;
        });
    }, [state.invoiceLines]);

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, itemID: null, showInvoice: false, showSellerInvoice: false }));
    };

    const inputChange = (e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handelSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            const data = {
                purchaseInvoiceJobID: props.scheduleJobID,
                purchaseInvoiceNumber: state.purchaseInvoiceNumber,
                purchaseInvoiceDate: state.purchaseInvoiceDate,
                purchaseInvoiceDateDue: state.purchaseInvoiceDateDue,
                purchaseInvoiceNominalCode: state.purchaseNominalCode,
                CreateInvoiceItems: state.invoiceLines
                    .filter((m) => m.isSelected)
                    .map((i) => {
                        if (i.isSelected) {
                            return {
                                itemID: i.itemID
                            };
                        }
                    })
            };
            let res = await createInvoice(data);
            if (res.success) {
                showToastSuccess('Invoice created successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    const validations = () => {
        const { purchaseInvoiceNumber, purchaseInvoiceDate, purchaseInvoiceDateDue, purchaseNominalCode } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(purchaseInvoiceNumber)) {
            errors.purchaseInvoiceNumber = 'Invoice number is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(purchaseInvoiceDate)) {
            errors.purchaseInvoiceDate = 'Invoice date is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(purchaseInvoiceDateDue)) {
            errors.purchaseInvoiceDateDue = 'Invoice due date is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(purchaseNominalCode)) {
            errors.purchaseNominalCode = 'Nominal code is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const handleCheckbox = (i) => (e) => {
        const { name, checked } = e.target;
        let changedArray = [...state.invoiceLines];
        let objIndex = state.invoiceLines.findIndex((x) => x.itemID == i);

        changedArray[objIndex][name] = checked;
        setState((state) => {
            const nst = { ...state, invoiceLines: changedArray };
            return nst;
        });
    };

    return (
        <div className="controller-screen">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Invoice Number"
                        name="purchaseInvoiceNumber"
                        placeholder="Invoice Number"
                        onChange={inputChange}
                        value={state.purchaseInvoiceNumber}
                        errors={state.errors.purchaseInvoiceNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <InputLabel shrink required>
                        Invoice Date
                    </InputLabel>
                    <DatePicker placeholder="Invoice Date" value={state.purchaseInvoiceDate} onChange={inputChange} name="purchaseInvoiceDate" />

                    <FormHelperText error>{state.errors.purchaseInvoiceDate}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <InputLabel shrink required>
                        Due Date
                    </InputLabel>
                    <DatePicker placeholder="Due Date" value={state.purchaseInvoiceDateDue} onChange={inputChange} name="purchaseInvoiceDateDue" />
                    <FormHelperText error>{state.errors.purchaseInvoiceDateDue}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Nominal Code"
                        name="purchaseNominalCode"
                        placeholder="Nominal Code"
                        onChange={inputChange}
                        value={state.purchaseNominalCode}
                        errors={state.errors.purchaseNominalCode}
                    />
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel>Clicking the OK button below will create invoice for following lines</InputLabel>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Create</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Part</TableCell>
                                    <TableCell>Labour Cost</TableCell>
                                    <TableCell>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.invoiceLines.map((row, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell style={{ color: 'inherit' }}>
                                                <SecondaryCheckbox
                                                    checked={row.isSelected}
                                                    name="isSelected"
                                                    onChange={handleCheckbox(row.itemID)}
                                                    label={row.name}
                                                />
                                            </TableCell>
                                            <TableCell style={{ color: 'inherit' }}>{row.labourDescription}</TableCell>
                                            <TableCell style={{ color: 'inherit' }}>
                                                {row.partsPrice ? <>£{row.partsPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                                            </TableCell>
                                            <TableCell style={{ color: 'inherit' }}>
                                                {row.labourCost ? <>£{row.labourCost.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                                            </TableCell>
                                            <TableCell style={{ color: 'inherit' }}>
                                                {row.total ? <>£{row.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {state.invoiceLines.length <= 0 && (
                                    <TableRow key={1}>
                                        <TableCell align="center" colSpan={4} style={{ color: 'red' }}>
                                            No Record Found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6} className="submit_btn">
                    <SecondaryButton isBtnLoader={btnLoader} disabled={!state.showOk} onClick={handelSubmit}>
                        Ok
                    </SecondaryButton>
                </Grid>
                <Grid item xs={12} sm={6} className="submit_btn">
                    <SecondaryButton onClick={() => props.onClose(false)}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
export default PurchaseInvoice;
