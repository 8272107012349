import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../../../../Core/Controls/Grid';
import { getAllBookingSchedule } from '../../../../Core/Services/ServiceAgent/Schedule/BookingReq';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import { Link } from 'react-router-dom';
import BuildIcon from '@material-ui/icons/Build';
import VehicleDailyChecks from '../../../InternalUser/Controllers/Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../../../InternalUser/Controllers/Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import WorkIcon from '@material-ui/icons/Work';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
// import ServiceAgentScheduleBookingSection from '../BookingRequest/BookingSection';
// import CompleteScheduleModal from './CompleteSchedule';
import CompleteScheduleModal from '../../../InternalUser/Schedule/completeSchedule';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import NoteIcon from '@material-ui/icons/Note';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleNotes from '../../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleNotes';
import '../../../commonStyle.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const crumbs = [{ name: 'Home', path: '/' }, { name: 'Schedule' }, { name: 'Confirmed Bookings', path: '/', active: true }];

const baseColumns = [
    { key: 'action', name: 'Action', width: 140, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg Number' },
    { key: 'agentOrderNo', name: 'Job Ref', width: 150 },
    { key: 'bookedDate', name: 'Booking', formatter: formatters.Date_DDMMYYYY },
    { key: 'scheduleDateDue', name: 'Due', formatter: formatters.Date_DDMMYYYY },
    { key: 'scheduleEventName', name: 'Event' },
    { key: 'customerCompanyName', name: 'Customer' },
    { key: 'depotName', name: 'Depot' }
];

const ConfirmedBookings = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        rowVehicleID: null,
        defectID: null,
        List: [],
        showLoader: true,
        showChecks: false,
        showEditChecks: false,
        selectedRowData: {},
        isReload: null
    });
    const [openDialog, setOpenDialog] = useState({});
    let checkhaveAccess = AppStorage.getAllowAcces();

    useEffect(async () => {
        pullAllBookingSchedule();
    }, []);

    const pullAllBookingSchedule = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleCheck = (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            if (val === 'Ad') {
                newSt.showChecks = true;
                newSt.selectedRowData = ev;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
                newSt.selectedRowData = ev;
            }
            return newSt;
        });
    };

    const checkButton = (ev, label) => {
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: ev,
            scheduleVehicleID: ev.scheduleVehicleID
        }));
    };

    const closeTheDialog = (res) => {
        setState((st) => ({
            ...st,
            showChecks: false,
            showEditChecks: false
        }));
        setOpenDialog({});
        res && pullAllBookingSchedule();
    };

    const renderEdit = (data, row) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <Link to={`/controller/jobTabScreen/${data.row?.scheduleJobID}/${data.row?.scheduleVehicleID}`} className="modal-btn-update ">
                    <CustomIconButton icon={WorkIcon} color="secondary" toolTipTitle="Book Job Details" />
                </Link> */}
                {row?.displayNotes === 'Y' && (
                    <CustomIconButton
                        icon={NoteIcon}
                        color="primary"
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'notes');
                        }}
                        toolTipTitle="Notes"
                    />
                )}
                {/* {row?.displayCompleteSchedule === 'Y' && ( */}
                <CustomIconButton
                    icon={CheckCircleIcon}
                    className="modal-btn-update "
                    color="primary"
                    onClick={() => {
                        checkButton(row, 'complete');
                    }}
                    // onClick={() => {
                    //     alert('Functionality Pending');
                    // }}
                    toolTipTitle="Complete Schedule"
                />
                {/* )} */}
                {row?.displayAddInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="primary"
                        onClick={() => {
                            handleCheck(row, 'Ad');
                        }}
                        toolTipTitle="Add Inspection Check"
                    />
                )}
                {row?.displayEditInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="secondary"
                        onClick={() => {
                            handleCheck(row, 'Ed');
                        }}
                        toolTipTitle="Edit Inspection Check"
                    />
                )}
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    let getUrl = `ServiceAgentSchedules?VehicleID=&Status=${5}`;

    return (
        <div className={!props.VehicleID ? `screen` : ''} style={{ marginTop: 8 }}>
            {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}

            {state.modalToggle ? (
                <h6>Update popup</h6>
            ) : state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.scheduleVehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    onClose={closeTheDialog}
                    frompage={true}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks checkID={state.selectedRowData.vehicleInspectionID} onClose={closeTheDialog} frompage={true} />
            ) : (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    // btnTitle={gridBtnTitle}
                    // onBtnClick={jobHandler}
                    isReload={state.isReload}
                    idFieldName={`${checkhaveAccess?.CanUploadScheduleDocuments ? 'scheduleID' : null}`}
                    fileUploaderConfigName={`${checkhaveAccess?.CanUploadScheduleDocuments ? 'ScheduleDocumentsRequired' : null}`}
                    downloadBtnName="Confirmed Bookings"
                />
            )}
            {/* {openDialog.section ? (
                <DialogComp  title="Booking Section" maxWidth="md" onClose={closeTheDialog}>
                    <CompleteScheduleModal scheduleID={state.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null} */}
            {openDialog.notes ? (
                <DialogComp title="Schedule Notes" maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleNotes vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.complete ? (
                <DialogComp title="Complete Schedule" maxWidth="sm" onClose={closeTheDialog}>
                    <CompleteScheduleModal rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ConfirmedBookings;
