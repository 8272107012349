import React, { useLayoutEffect } from 'react';
import clsx from 'clsx';
import { NavLink, Route, Switch } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useCurrentMenus } from '../Hooks/useMenu';
import { useLoginInfo } from '../Hooks/useLoginInfo';
import { usePortalSettings } from '../Hooks/usePortalSettings';
import { Error404, InternalDashboard } from '../../Components';
import _ from 'lodash';
import { useEffect } from 'react';
import { getMenuItems } from '../Services/menuService';
import { useState } from 'react';
import { useLoader } from '../Hooks/useLoader';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../Hooks/useWindowSize';
import * as Icons from '@material-ui/icons';
import { GetAllowMenus } from './getAllowMenus';
import { AppStorage } from '../Basic/storage-service';

const drawerWidth = 235;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    logo: {
        maxWidth: 173,
        maxHeight: 50
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        overflowX: 'auto',
        margin: 0,
        padding: 0,
        listStyle: 'none',
        height: '100%',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        },
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        backgroundColor: (props) => `${props.portalMenuBarBackgroundColour}`,
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(0) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6) + 1
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0)
    },
    nested: {
        paddingLeft: theme.spacing(1)
    },
    itemText: {
        color: (props) => `${props.portalMenuBarTextColour}`,
        '& span, & svg': {
            fontSize: '0.89rem',
            color: (props) => `${props.portalMenuBarTextColour}`
        }
    }
}));

export default function MiniDrawer(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const portalSettings = usePortalSettings();
    const { isLoggedIn, userRoles } = useLoginInfo();

    const { showLoader, hideLoader } = useLoader();
    const currentMenus = useCurrentMenus();
    const theme = useTheme();
    const classes = useStyles(portalSettings);
    let history = useHistory();
    const [state, setState] = React.useState({ isError404: false });
    const [myMenus, setMyMenus] = useState([]);
    const [myRoutes, setMyRoutes] = useState([]);
    // const windowScreenWidth = window.innerWidth;
    const [windowWidth, windowHeight] = useWindowSize();
    const handleDrawerClose = () => {
        props.closeDrawer();
        setState({ isError404: false });
    };
    let isAdmin = userRoles.includes('admin');

    const handleClick = (item) => {
        setState((st) => ({ ...st, [item]: !st[item] }));
    };

    const getNestedChildren = (arr, parent) => {
        var out = [];
        for (var i in arr) {
            if (arr[i].navigationMenuParentMenuID === parent) {
                var children = getNestedChildren(arr, arr[i].navigationMenuID);
                if (children.length) {
                    arr[i].children = children;
                }
                out.push(arr[i]);
            }
        }
        let menusFromApi = out.sort((a, b) =>
            a.navigationMenuSequence > b.navigationMenuSequence ? 1 : b.navigationMenuSequence > a.navigationMenuSequence ? -1 : 0
        );
        return menusFromApi;
    };

    useEffect(async () => {
        setState((st) => ({ ...st, isError404: false }));
        showLoader();
        const res = isLoggedIn && (await getMenuItems());

        if (res.success) {
            let newArray = [...res.data.list];

            if (!isAdmin) {
                const menusToCheck = [
                    'Daily Checks',
                    'Jobs',
                    'Drivers',
                    'Depots',
                    'Vehicles',
                    'Invoices',
                    'Schedules',
                    'Service Agent',
                    'Breakdowns',
                    'Defects',
                    'VOR'
                ];

                menusToCheck.forEach((menuLabel) => {
                    if (!checkhaveAccess?.[`CanView${menuLabel}`]) {
                        const index = newArray.findIndex((m) => m.navigationMenuLabel === menuLabel);
                        if (index > -1) {
                            newArray.splice(index, 1);
                        }
                    }
                });
            }

            hideLoader();
            let menusFromApi = newArray.sort((a, b) =>
                a.navigationMenuSequence > b.navigationMenuSequence ? 1 : b.navigationMenuSequence > a.navigationMenuSequence ? -1 : 0
            );
            let menus = isLoggedIn ? GetAllowMenus(menusFromApi) : menusFromApi;
            if (process.env.NODE_ENV !== 'production') {
                menusFromApi.push({
                    leftNavigationMenuIconName: 'DashboardIcon',
                    screenLabel: 'ⓘ',
                    navigationMenuID: 0,
                    navigationMenuLabel: 'ⓘ',
                    navigationMenuParentMenuID: null,
                    navigationMenuSequence: 100,
                    screenURL: '/help',
                    screenComponent: '1',
                    screenAuthType: '1'
                });
            }

            const possibleMenusFlat = [];
            menus.forEach((mi) => {
                var menuConfig = currentMenus.find((x) => x.path === mi.screenURL);
                if (!menuConfig) {
                    menuConfig = mi;
                }
                if (menuConfig) {
                    let obj = {
                        navigationMenuID: mi.navigationMenuID,
                        // leftNavigationMenuRoleID: mi.leftNavigationMenuRoleID,
                        // leftNavigationMenuIconID: mi.leftNavigationMenuIconID,
                        screenURL: mi.screenURL || '',
                        leftNavigationMenuComponent: menuConfig.component || null,
                        screenAuthType: mi.screenAuthType || menuConfig.authType,
                        navigationMenuParentMenuID: mi.navigationMenuParentMenuID,
                        navigationMenuSequence: mi.navigationMenuSequence,
                        navigationMenuLabel: mi.navigationMenuLabel || mi.screenLabel,
                        leftNavigationMenuIconName: mi.leftNavigationMenuIconName || menuConfig.icon,
                        exact: menuConfig.exact || true
                    };
                    if (mi.leftNavigationMenuIconName) {
                        let Comp = Icons[`${mi.leftNavigationMenuIconName.replace('Icon', '')}`];
                        obj.leftNavigationMenuIconName = <Comp />;
                    }
                    possibleMenusFlat.push(obj);
                }
            });
            setMyRoutes(possibleMenusFlat.map((c) => ({ ...c })));
            setMyMenus(
                getNestedChildren(
                    possibleMenusFlat.map((c) => ({ ...c })),
                    null
                )
            );
            setState((st) => ({ ...st, isError404: true }));
        } else {
            hideLoader();
            isLoggedIn && history.push('/logout');
            setState((st) => ({ ...st, isError404: true }));
        }
    }, [isLoggedIn]);
    const img = !!portalSettings.portalSettingMainLogo ? portalSettings.portalSettingMainLogo : `${process.env.PUBLIC_URL}/${portalSettings.portalSettingLogo}`;

    const handler = (children, isFiltered) => {
        let currentNav = children;
        // isFiltered ? _.orderBy(children, [(c) => c.navigationMenuLabel.toLowerCase()], ['asc']) : children;
        return currentNav?.map((subOption, index) => {
            if (!subOption.children) {
                return (
                    <div key={subOption.navigationMenuLabel}>
                        <NavLink activeClassName="active-link" exact to={subOption.screenURL} className="text-and-icon">
                            <ListItem
                                className="text-and-icon"
                                button
                                size="small"
                                key={subOption.navigationMenuLabel}
                                title={subOption.navigationMenuLabel}
                                onClick={windowWidth > 1200 ? () => '' : handleDrawerClose}
                            >
                                <ListItemIcon
                                    // onClick={() => props.opneDrawer()}
                                    className="sidebar-icon"
                                    style={{ color: `${portalSettings.portalMenuBarTextColour}` }}
                                >
                                    {subOption.leftNavigationMenuIconName}
                                </ListItemIcon>
                                <ListItemText className={classes.itemText}>{subOption.navigationMenuLabel}</ListItemText>
                            </ListItem>
                        </NavLink>
                    </div>
                );
            }
            return (
                <div key={subOption.navigationMenuLabel}>
                    <ListItem className="text-and-icon" button onClick={() => handleClick(subOption.navigationMenuLabel)} title={subOption.navigationMenuLabel}>
                        <ListItemIcon
                            onClick={() => props.opneDrawer()}
                            className="sidebar-icon"
                            style={{ color: `${portalSettings.portalMenuBarTextColour}` }}
                        >
                            {subOption.leftNavigationMenuIconName}
                        </ListItemIcon>
                        <ListItemText className={classes.itemText}>{subOption.navigationMenuLabel}</ListItemText>
                        <ListItemIcon style={{ display: 'flex', justifyContent: 'flex-end' }} className="text-and-icon">
                            {state[subOption.navigationMenuLabel] ? (
                                <ExpandLess style={{ color: `${portalSettings.portalMenuBarTextColour}` }} />
                            ) : (
                                <ExpandMore style={{ color: `${portalSettings.portalMenuBarTextColour}` }} />
                            )}
                        </ListItemIcon>
                    </ListItem>
                    <Collapse className={classes.nested} in={state[subOption.navigationMenuLabel]} timeout="auto" unmountOnExit>
                        {handler(subOption.children, true)}
                    </Collapse>
                </div>
            );
        });
    };

    return (
        <div className={classes.root}>
            {isLoggedIn && (
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: props.isDrawer,
                        [classes.drawerClose]: !props.isDrawer
                    })}
                    classes={{
                        paper: clsx({ [classes.drawerOpen]: props.isDrawer, [classes.drawerClose]: !props.isDrawer })
                    }}
                >
                    <div className={classes.toolbar}>
                        <img src={img} alt="logo" className={classes.logo} />
                        <IconButton className="text-and-icon" onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>{handler(myMenus, false)}</List>
                </Drawer>
            )}

            <main
                className={classes.content + ' component-container'}
                style={{ width: `${windowWidth < 400 ? windowWidth + 'px' : windowWidth - 235 + 'px'}`, overflow: 'auto' }}
            >
                <div className={classes.toolbar} />
                <Switch>
                    {isLoggedIn
                        ? currentMenus
                              .filter((m) => m.hidden)
                              .map((m, i) => {
                                  return <Route key={i} exact={m.exact} path={m.path} component={m.component}></Route>;
                              })
                        : currentMenus.map((m, i) => {
                              return <Route key={i} exact={m.exact} path={m.path} component={m.component}></Route>;
                          })}
                    {isLoggedIn &&
                        myRoutes
                            .filter((m, index) => !!m.screenURL)
                            .map((n, i) => {
                                return <Route key={i} exact={true} path={n.screenURL} component={n.leftNavigationMenuComponent}></Route>;
                            })}
                    {state.isError404 && <Route component={Error404}></Route>}
                </Switch>
            </main>
        </div>
    );
}
