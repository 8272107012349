import React from 'react';
import { FormHelperText, Grid, IconButton, InputLabel, Tooltip } from '@material-ui/core';
import { DateTimePicker, MandatoryField, SecondaryButton, SecondaryCheckbox, SelectBox } from '../../../../../Core/Controls';
import { useState } from 'react';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import ConfirmationModal from '../../../../../Core/Modal/ConfirmationModal';
import { postAgentArrivalTime } from '../../../../../Core/Services/InternalUser/Maintenance/DefectBreackDownStepper/DefectBreackDownStepper';
import { setBookingDate, setCompletionDate, setVehicleArrive } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { useToast } from '../../../../../Core/Hooks/useToast';
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SignOff from './jobCompletionModal';
import moment from 'moment';
import { useEffect } from 'react';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { getAllDelayReason } from '../../../../../Core/Services/InternalUser/bookingDelayReason';
import { usePortalSettings } from '../../../../../Core/Hooks/usePortalSettings';
import JobStepperScreen from './jobHeaderDateStepper';
import { Stepper } from '@material-ui/core/Stepper';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const JobItemHeaderDates = (props) => {
    const portalSettings = usePortalSettings();
    const { isLoggedIn, displayName, lastLogin, userRoles } = useLoginInfo();

    const isServiceAgent = userRoles.includes('serviceagent');
    const isCustomer = userRoles.includes('customer');
    const isinternal = userRoles.includes('internal');

    const [state, setState] = useState({
        vehicleArrivalDateCheck: false,
        jobCompleteDateCheck: false,
        bookingDateCheck: false,
        bookingDate: '',
        arrivedDate: '',
        jobCompletionDate: '',
        isjobCompletionDate: false,
        isarrivedDate: false,
        errors: {}
    });

    useEffect(async () => {
        setState((st) => ({
            ...st,
            bookingDate: props.jobDetails.bookingDate ? moment(props.jobDetails.bookingDate).format('YYYY-MM-DDTHH:mm') : '',
            isbookingDate: props.jobDetails.jobStatusID === 'JD' || props.jobDetails.jobStatusID === 'JP' ? true : false,
            arrivedDate: props.jobDetails.vehicleArrived ? moment(props.jobDetails.vehicleArrived).format('YYYY-MM-DDTHH:mm') : '',
            isarrivedDate: props.jobDetails.vehicleArrived ? true : false,
            jobCompletionDate: props.jobDetails.completionDate ? moment(props.jobDetails.completionDate).format('YYYY-MM-DDTHH:mm') : '',
            isjobCompletionDate: props.jobDetails.completionDate ? true : false,
            isReadOnly: isServiceAgent || isinternal ? false : true
        }));
    }, [props.jobDetails]);
    const { showToastSuccess, showToastError } = useToast();

    const dateFieldsChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleClick = (dateFlowName) => {
        // const { name, checked } = e.target;
        let errors = {};
        if (dateFlowName == 'vehicleArrivalDateCheck') {
            if (!state.arrivedDate) {
                errors.arrivedDate = 'Please set Arrival Date & Time';
                setState((state) => ({ ...state, errors: errors }));
            } else {
                setState((st) => ({
                    ...st,
                    [dateFlowName]: true,
                    confirmModalTitle: 'Set Arrival Time',
                    confirmModalText: `Please click the confirm button to set the vehicle arrival date & time`,
                    errors: errors
                }));
            }
        } else if (dateFlowName == 'jobCompleteDateCheck') {
            if (!state.jobCompletionDate) {
                errors.jobCompletionDate = 'Please set Completion Date & Time';
                setState((state) => ({ ...state, errors: errors }));
            } else {
                setState((st) => ({
                    ...st,
                    [dateFlowName]: true,
                    confirmModalTitle: 'Job Complete Date',
                    confirmModalText: `Please click the confirm button to mark job as completed`,
                    errors: errors
                }));
            }
        } else if (dateFlowName == 'bookingDateCheck') {
            if (!state.bookingDate) {
                errors.bookingDate = 'Please set Booking Date & Time';
                setState((state) => ({ ...state, errors: errors }));
            } else {
                setState((st) => ({
                    ...st,
                    [dateFlowName]: true,
                    errors: errors
                }));
            }
        }
    };
    const handleConfirmPopup = (res) => {
        res && props.refreshScreen();
        setState((st) => ({
            ...st,
            vehicleArrivalDateCheck: false,
            jobCompleteDateCheck: false,
            bookingDateCheck: false
        }));
    };

    const submitData = async () => {
        let FormData;
        if (state.vehicleArrivalDateCheck) {
            FormData = {
                jobID: props.jobID,
                arrivedDate: state.arrivedDate,
                serviceAgentID: props.jobDetails.jobServiceAgentID
            };

            let res = await setVehicleArrive(FormData);
            if (res.success) {
                showToastSuccess(`Arrival date set successfully`);
                handleConfirmPopup(true);
            } else {
                showToastError(res.message);
            }
        }
        // if (state.jobCompleteDateCheck) {
        //     FormData = {
        //         jobID: props.jobID,
        //         jobCompleteDateCheck: state.jobCompleteDateCheck
        //     };
        //     let res = await setCompletionDate(FormData);
        //     if (res.success) {
        //         showToastSuccess(`added successfully`);
        //         handleConfirmPopup();
        //     } else {
        //         showToastError(res.message);
        //     }
        // }
    };

    // const StepperList = [
    //     {
    //         name: 'Booking Date',
    //         icon: CalendarTodayIcon
    //     },
    //     {
    //         name: 'Vehicle Arrival Date',
    //         icon: CalendarTodayIcon
    //     },
    //     {
    //         name: 'Job Complete Date',
    //         icon: CalendarTodayIcon
    //     }
    // ];

    return (
        //date .........
        <Grid item container spacing={1}>
            <JobStepperScreen />
            <Grid item xs={12} sm={6} md={6} lg={4} alignItems="center">
                <Grid container spacing={1}>
                    <Grid item xs={11}>
                        <InputLabel shrink>Booking Date</InputLabel>
                        <DateTimePicker
                            placeholder="Date"
                            value={state.bookingDate}
                            onChange={dateFieldsChange}
                            name="bookingDate"
                            // InputProps={{ inputProps: { max: `${state.bookingDate}` } }}
                            disabled={state.isbookingDate || state.isReadOnly}
                        />
                        <FormHelperText error>{state.errors.bookingDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={1} style={{ padding: '25px 0px 0px 0px', marginLeft: -4 }}>
                        <Tooltip title="Click here set Booking Date">
                            <IconButton aria-label="delete" style={{ padding: '0px' }} disabled={state.isbookingDate || state.isReadOnly}>
                                <UpdateIcon onClick={() => handleClick('bookingDateCheck')} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} alignItems="center">
                <Grid container spacing={1}>
                    <Grid item xs={11}>
                        <InputLabel shrink>Vehicle Arrival Date</InputLabel>
                        <DateTimePicker
                            placeholder="Date"
                            value={state.arrivedDate}
                            onChange={dateFieldsChange}
                            name="arrivedDate"
                            // InputProps={{ inputProps: { max: `${state.arrivedDate}` } }}
                            disabled={state.isarrivedDate || state.isReadOnly}
                        />
                        <FormHelperText error>{state.errors.arrivedDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={1} style={{ padding: '25px 0px 0px 0px', marginLeft: -4 }}>
                        <Tooltip title="Click here set Arrival Date" placement="bottom">
                            <IconButton aria-label="delete" style={{ padding: '0px' }} disabled={state.isarrivedDate || state.isReadOnly}>
                                <UpdateIcon onClick={() => handleClick('vehicleArrivalDateCheck')} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} alignItems="center">
                <Grid container spacing={1}>
                    <Grid item xs={11}>
                        <InputLabel shrink>Job Complete Date</InputLabel>
                        <DateTimePicker
                            placeholder="Date"
                            value={state.jobCompletionDate}
                            onChange={dateFieldsChange}
                            name="jobCompletionDate"
                            // InputProps={{ inputProps: { max: `${state.jobCompletionDate}` } }}
                            disabled={state.isjobCompletionDate || state.isReadOnly}
                        />
                        <FormHelperText error>{state.errors.jobCompletionDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={1} style={{ padding: '25px 0px 0px 0px', marginLeft: -4 }}>
                        <Tooltip title="Click here to mark job as Completed">
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                {state.isjobCompletionDate || state.isReadOnly ? (
                                    <CheckCircleIcon style={{ color: 'green' }} onClick={() => handleClick('jobCompleteDateCheck')} />
                                ) : (
                                    <UpdateIcon onClick={() => handleClick('jobCompleteDateCheck')} />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            {state.vehicleArrivalDateCheck ? (
                <DialogComp title={state.confirmModalTitle} onClose={() => handleConfirmPopup(false)} maxWidth="sm" fullWidth>
                    <ConfirmationModal
                        message={state.confirmModalText}
                        handleCancel={() => handleConfirmPopup(false)}
                        handleSubmit={submitData}
                        title={state.confirmModalTitle}
                    />
                </DialogComp>
            ) : null}
            {state.jobCompleteDateCheck ? (
                <DialogComp title={state.confirmModalTitle} onClose={() => handleConfirmPopup(false)} maxWidth="sm" fullWidth>
                    <SignOff
                        jobID={props.jobID}
                        jobDetails={props.jobDetails}
                        jobCompletionDate={state.jobCompletionDate}
                        displayName={displayName}
                        declaration={portalSettings.portalSettingTechnicianDeclaration}
                        onClose={() => handleConfirmPopup(true)}
                    />
                </DialogComp>
            ) : null}
            {state.bookingDateCheck ? (
                <DialogComp title={'Booking Date'} onClose={() => handleConfirmPopup(false)} maxWidth="sm" fullWidth>
                    <BookingDateModal
                        onClose={() => handleConfirmPopup(false)}
                        bookingDate={state.bookingDate}
                        jobID={props.jobID}
                        refreshScreen={props.refreshScreen}
                    />
                </DialogComp>
            ) : null}
        </Grid>
    );
};

export default JobItemHeaderDates;

const BookingDateModal = (props) => {
    const [state, setState] = useState({ errors: {}, jobReasonList: [] });
    const { showToastSuccess, showToastError } = useToast();

    const dateFieldsChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    useEffect(async () => {
        let listReason = await getAllDelayReason();
        let jobReasonList = listReason.data.list.map((m) => ({
            id: m.bookingDateDelayReasonID,
            name: m.bookingDateDelayReasonDescription
        }));

        setState((st) => ({
            ...st,
            jobReasonList: jobReasonList
        }));
    }, []);

    const handleSubmit = async () => {
        let FormData = {
            bookingNotes: state.bookingNotes,
            bookingReasonID: state.bookingReasonID,
            bookingDate: props.bookingDate,
            jobID: props.jobID
        };
        let res = await setBookingDate(FormData);
        if (res.success) {
            showToastSuccess(`Booking date set successfully`);
            // props.refreshScreen(true);
            props.onClose(true);
        } else {
            showToastError(res.message);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    Select Reason
                </InputLabel>
                <SelectBox name="bookingReasonID" value={state.bookingReasonID || ' '} onChange={handleInputChange} List={state.jobReasonList} />
                <FormHelperText error>{state.errors.bookingReasonID}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <MandatoryField
                    multiline
                    rows={6}
                    inputLabel="Comment"
                    placeholder="Comment"
                    autoFocus={true}
                    value={state.bookingNotes}
                    name="bookingNotes"
                    onChange={dateFieldsChange}
                    errors={state.errors.bookingNotes}
                />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={handleSubmit}>Add</SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};
