import React, { useState, useEffect, useMemo } from 'react';
import { getAllJobItems } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { getJobDetailsById, getJobRequiredDocuments } from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DataGrid from '../../../../../../Core/Controls/Grid';
import { AddButton, CustomChip, EditButton, formatters } from '../../../../../../Core/Controls';
import AddLabourModal from './addJobItem';
import '../../../../../commonStyle.scss';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, Typography } from '@material-ui/core';
import { some } from 'lodash';
import { useHistory } from 'react-router-dom';
import SellerInvoiceModal from '../../JOBItems/JobInvoiceLines/sellerInvoice';
import PurchaseInvoice from '../../JOBItems/JobInvoiceLines/purchaseInvoice';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import JobItemHeaderDates from './../../JOBItems/jobItemHeaderDates';
import FileUploaderButton from '../../../../../../Core/Controls/GenericFileUploader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import JobQueriesScreen from '../../jobQueriesScreen';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import _ from 'lodash';
import UpcomingEvents from '../../../Vehicles/ScheduleVehicle/upcomingEvents';
import { CustomIconButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import StickyNotes from '../../../Notes/stickyNotes';
import BuildIcon from '@material-ui/icons/Build';
import VehicleDailyChecks from '../../../Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../../../Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import CompleteScheduleModal from '../../../../Schedule/completeSchedule';

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

function InternalJobItem(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        jobItemsList: [],
        loader: true,
        open: false,
        jobStatusID: '',
        showInvoice: false,
        refresherFlag: new Date(),
        errors: {},
        showOk: true,
        showSellerBtn: true,
        showSellerInvoice: false,
        showCreate: false
    });
    const history = useHistory();

    const dataReload = () => {
        setState((state) => ({ ...state, refresherFlag: new Date() }));
    };

    const base_columns = [
        {
            key: 'action',
            name: 'Action',
            width: 100
        },
        {
            key: 'jobItemDescription',
            name: 'Description',
            formatter: (params) => {
                return (
                    <div>
                        <div>{params.row.jobItemDescription}</div>
                        <div style={{ fontSize: '12px' }}>{params.row.vmrs}</div>
                    </div>
                );
            },
            cellClass: 'react-data-grid-cell-line-height'
        },
        {
            key: 'jobItemPartsprice',
            name: 'Parts',
            formatter: formatters.NumberWithPound,
            headerRenderer: formatters.ColumnHeaderAlignRight,
            width: 90
        },
        { key: 'totalLabour', name: 'Labour', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight, width: 90 },
        { key: 'total', name: 'Total', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight, width: 90 },
        { key: 'custTotal', name: 'Cust Total', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight, width: 90 }
        // { key: 'jobItemStatusDescription', name: 'Status' }
    ];

    useEffect(async () => {
        pullJobDetails();
        pullJobItems();
    }, [state.refresherFlag]);

    const [jobDetails, setJobDetails] = useState({
        jobDescription: '',
        jobDepotID: '',
        jobServiceAgentID: '',
        vehicleMake: '',
        vehicleModel: '',
        jobRegNumber: '',
        refreshDependancy: ''
    });

    const [jobdocument, setJobDocument] = useState({
        list: []
    });

    const pullJobDetails = async () => {
        let res = await getJobDetailsById(props.scheduleJobID);

        let res1 = res.data.list;
        let data = res1?.length > 0 ? res.data?.list[0] : res.data?.list;
        if (data) {
            setJobDetails((st) => ({
                ...st,
                jobID: data.jobID,
                jobAgentOrderNo: data.jobAgentOrderNo,
                jobOrderDate: formatters.DateOnlyFormatter(data.jobDate),
                jobOrderTotal: data.orderTotal,
                jobEstimatedCost: data.jobEstimatedCost || '',
                jobServiceAgentID: data.jobServiceAgentID || '',
                jobVehicleID: data.jobVehicleID,
                jobRegNumber: data.jobRegNumber,
                vehicleMake: data.vehicleMake,
                vehicleModel: data.vehicleModel,
                vehicleJobStatus: data.vehicleJobStatus,
                jobStatusID: data.jobStatusID,
                jobApprovedOrderNo: data.jobApprovedOrderNo,
                mileageRequired: data.mileageRequired,
                customerName: data.customerCompanyName,
                jobDescription: data.jobDescription,
                bookingDate: data.bookingDate,
                completionDate: data.completionDate,
                vehicleArrived: data.vehicleArrived,
                jobMileageDate: data.jobMileageDate || '',
                jobMileage: data.jobMileage || '',
                refreshDependancy: new Date(),
                jobCustomerOrderTotal: data.jobCustomerOrderTotal || 0,
                jobOrderNo: data.jobOrderNo,
                jobIsEstimate: data.jobIsEstimate,
                islabourRateSet: data.labourRate == 0 ? false : true,
                labourRate: data.labourRate,
                jobInAgentQuery: data.jobInAgentQuery,
                markup: data.markup,
                signOffNotes: data.signOffNotes,
                signOffSignature: data.signOffSignature,
                signedOffByName: data.signedOffByName
            }));
        }
    };

    useEffect(async () => {
        if (jobDetails.jobID) {
            let res = await getJobRequiredDocuments(jobDetails.jobID);
            setJobDocument((state) => ({ ...state, list: res.data.list }));
        }
    }, [jobDetails.jobID]);

    const pullJobItems = async () => {
        let res = await getAllJobItems(props.scheduleJobID);
        let res2 = res.data.vehicleJobItems.map((m) => {
            let customerLabour = m.jobItemLabHourRate * (1 + state.markup / 100);
            let Labour = (m.jobItemLabHourRate * m.jobItemLabourHours * (100 - m.jobItemLabourDiscount)) / 100;
            return {
                custTotal: m.jobItemCustomerLabourCost + m.jobItemCustomerPartsPrice,
                totalLabour: Labour,
                total: Labour + m.jobItemPartsprice,
                ...m
            };
        });

        setState((state) => {
            const nst = { ...state };
            nst.jobItemsList = res2;
            nst.loader = false;
            nst.checkApprovedJob = some(res.data.vehicleJobItems, (r) => r.jobItemStatusID == 'A');
            return nst;
        });
    };

    const btnHandler = (params) => {
        setState((state) => ({ ...state, open: true, jobItemID: params.jobItemID, vehicleID: params.vehicleID, vehicleRegNumber: params.vehicleRegNumber }));
    };

    const createInvoiceHandler = (name) => {
        setState((state) => ({ ...state, [name]: true }));
    };

    const closeCheck = () => {
        setState((st) => ({
            ...st,
            showChecks: false,
            showEditChecks: false,
            complete: false
        }));
        // pullApiData();
    };

    const handleCheck = (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.selectedRowData = ev;
            if (val === 'Ad') {
                newSt.showChecks = true;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
            }
            if (val === 'complete') {
                newSt.complete = true;
            }
            return newSt;
        });
    };

    const ActionButtons = (data) => {
        return (
            <div style={{ textAlign: 'left', marginLeft: '10px' }}>
                <EditButton toolTipTitle="Edit Job Item" onClick={() => btnHandler(data.row)} />
                {data.row?.displayCompleteSchedule === 'Y' && (
                    <CustomIconButton
                        icon={CheckCircleIcon}
                        className="modal-btn-update btn_space"
                        color="primary"
                        onClick={() => {
                            handleCheck(data.row, 'complete');
                        }}
                        toolTipTitle="Complete schedule"
                    />
                )}
                {data.row?.displayAddInspectionCheck === 'Y' && checkhaveAccess?.CanAddInspections && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="primary"
                        onClick={() => {
                            handleCheck(data.row, 'Ad');
                        }}
                        toolTipTitle="Add Inspection Check"
                    />
                )}

                {data.row?.displayEditInspectionCheck === 'Y' && (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="secondary"
                        onClick={() => {
                            handleCheck(data.row, 'Ed');
                        }}
                        toolTipTitle="Edit Inspection Check"
                    />
                )}
            </div>
        );
    };

    let checkStatus = ['AA', 'JP', 'JQ', 'EA', 'EU'].includes(jobDetails.jobStatusID);
    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
            actionCol.headerRenderer = () => {
                if (checkStatus) {
                    return (
                        <Grid container alignItems="center">
                            <AddButton toolTipTitle="Add Job Item" onClick={btnHandler} />
                            {checkhaveAccess?.canAddScheduleItemstoJob && (
                                <CustomIconButton
                                    icon={UpdateIcon}
                                    className="modal-btn-update "
                                    onClick={() => createInvoiceHandler('showUpComingEvents')}
                                    toolTipTitle="Upcoming Events"
                                />
                            )}
                            <span style={{ marginBottom: '10px', marginLeft: '5px' }}>Action</span>
                        </Grid>
                    );
                } else {
                    return null;
                }
            };
        }

        return newColumn;
    }, [checkStatus]);

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, jobItemID: null, showInvoice: false, showSellerInvoice: false, showUpComingEvents: false }));
        res && refreshScreen();
    };

    const refreshScreen = () => {
        setJobDetails((st) => ({ ...st, refreshDependancy: new Date() }));
        pullJobDetails();
        pullJobItems();
    };

    const getMakeModel =
        jobDetails.vehicleMake || jobDetails.vehicleModel
            ? ` (${jobDetails.vehicleMake ? jobDetails.vehicleMake : ''} ${jobDetails.vehicleModel ? jobDetails.vehicleModel : ''})`
            : '';
    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${props.VehicleID}`,
            search: ''
        });
    };

    return (
        <div className="controller-screen">
            <StickyNotes frompage="Jobs" noteRefsGuidID={props.scheduleJobID} />
            <div className="controller-screen">
                <Grid container spacing={0} className="mt_10">
                    <Grid item xs={12} lg={12}>
                        <Grid item container justify="flex-start" xs={12} style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                            {jobDetails?.jobOrderNo ? (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                                    <FormHelperText shrink>Final Order No </FormHelperText>
                                    <Typography variant="button">
                                        {jobDetails?.jobOrderNo || ''}({jobDetails?.jobAgentOrderNo})
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                                    <FormHelperText shrink>Job Reference</FormHelperText>
                                    <Typography variant="button">{jobDetails?.jobAgentOrderNo || ''}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={6} sm={6} md={3} lg={2} xl={1}>
                                <FormHelperText shrink>Order Date</FormHelperText>
                                <Typography variant="button">{jobDetails.jobOrderDate}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={5} lg={2} xl={3}>
                                <FormHelperText shrink>Customer</FormHelperText>
                                <Typography variant="button">{jobDetails.customerName}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={2} xl={4}>
                                <FormHelperText shrink>Vehicle Detail</FormHelperText>
                                <Typography variant="button">
                                    <span onClick={handleEditRegClick} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                        {jobDetails.jobRegNumber}
                                    </span>{' '}
                                    {getMakeModel}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                                <div style={{ width: '100%', textAlign: 'right' }}>
                                    <FormHelperText style={{ textAlign: 'end' }}>Total</FormHelperText>
                                    <Typography variant="button" style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>
                                        £{jobDetails.jobOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={jobdocument.list.length > 0 ? 6 : 12} md={jobdocument.list.length > 0 ? 7 : 12} style={{ marginTop: 3 }}>
                                <Grid container spacing={1} className="alert-container">
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Alert icon={false} severity={alertBoxColorCode[jobDetails.jobStatusID]} className="alert-items">
                                            <span style={{ display: 'block', fontSize: '12px' }}>Status</span>
                                            <span style={{ fontWeight: 'bold' }}>{jobDetails.vehicleJobStatus}</span>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8}>
                                        <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                                            <span style={{ display: 'block', fontSize: '12px' }}>Job Description</span>
                                            <span style={{ fontWeight: 'bold' }}>{jobDetails.jobDescription}</span>
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {jobdocument.list.length > 0 && (
                                <Grid item xs={12} sm={6} md={5} style={{ marginTop: 3 }}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                            height: '95%',
                                            backgroundColor: '#f5f9f9',
                                            padding: '6px 16px'
                                        }}
                                    >
                                        {jobdocument.list.map((m) => {
                                            return (
                                                <Grid item>
                                                    {false ? (
                                                        <div style={{ paddingBottom: '5px' }}>
                                                            {m.docName}{' '}
                                                            <span style={{ position: 'relative', top: '7px', left: '2px', marginRight: '15px' }}>
                                                                <CheckCircleIcon style={{ color: 'green' }} />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div style={{ paddingTop: '5px' }}>
                                                            {m.docName}
                                                            <span style={{ position: 'relative', top: '-7px', left: '7px', marginRight: '15px' }}>
                                                                <FileUploaderButton
                                                                    recordId={m.scheduleDocID}
                                                                    fileUploaderConfigName="ScheduleDocumentsRequired"
                                                                    fileCount={m.fileCount}
                                                                    gridRelaod={dataReload}
                                                                    docIDPassed={true}
                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {state.showSellerInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Sales Invoice" maxWidth="lg" fullScreen>
                    <SellerInvoiceModal onClose={closeModal} scheduleJobID={props.scheduleJobID} />
                </DialogComp>
            )}
            {state.showInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Purchase Invoice" maxWidth="md">
                    <PurchaseInvoice onClose={closeModal} scheduleJobID={props.scheduleJobID} />
                </DialogComp>
            )}
            {state.open ? (
                <AddLabourModal
                    onClose={closeModal}
                    vehicleID={state.vehicleID}
                    jobItemID={state.jobItemID}
                    Status={jobDetails.jobStatusID}
                    vehicleRegNumber={state.vehicleRegNumber}
                    jobItemJobID={props.scheduleJobID}
                    islabourRateSet={jobDetails.islabourRateSet}
                    labourRate={jobDetails.labourRate}
                    markup={jobDetails.markup}
                />
            ) : state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.scheduleVehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    onClose={closeCheck}
                    frompage={false}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks checkID={state.selectedRowData.vehicleInspectionID} onClose={closeCheck} frompage={false} />
            ) : (
                <div style={{ marginTop: 8 }}>
                    {jobDetails.jobStatusID == 'CA' && (
                        <Grid container spacing={1}>
                            <Grid item>
                                <CustomChip
                                    icon={AddIcon}
                                    label="Create Purchase Invoice"
                                    onClick={() => createInvoiceHandler('showInvoice')}
                                    style={{ fontWeight: 'bold' }}
                                />
                            </Grid>
                            <Grid item>
                                <CustomChip
                                    icon={AddIcon}
                                    label="Create Sales Invoice"
                                    onClick={() => createInvoiceHandler('showSellerInvoice')}
                                    style={{ fontWeight: 'bold' }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container xs={12} sm={12} md={12} lg={7}>
                        <JobItemHeaderDates jobID={jobDetails.jobID} jobDetails={jobDetails} refreshScreen={refreshScreen} />
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={7}>
                            <DataGrid columns={columns} rows={state.jobItemsList} isRowHovered={true} rowHeight={50} loadingData={state.loader} height={475} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} style={{ paddingRight: '10px' }}>
                            <JobQueriesScreen
                                VehicleID={props.VehicleID}
                                JobID={props.scheduleJobID}
                                fromPage="job"
                                refreshDependancy={jobDetails.refreshDependancy}
                            />
                        </Grid>
                    </Grid>
                    {state.showUpComingEvents ? (
                        <DialogComp title={'Upcoming Events'} onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                            <UpcomingEvents vehicleID={jobDetails.jobVehicleID} fromPage="jobItem" />
                        </DialogComp>
                    ) : null}
                    {state.complete ? (
                        <DialogComp title={`Complete Schedule  (${jobDetails.jobRegNumber})`} maxWidth="sm" onClose={() => closeCheck(false)}>
                            <CompleteScheduleModal scheduleID={state.scheduleID} rowData={state.selectedRowData} onClose={closeCheck} />
                        </DialogComp>
                    ) : null}
                </div>
            )}
        </div>
    );
}
export default InternalJobItem;
