import { FormHelperText, Grid, Typography, InputLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { DatePicker, YesButton, NoButton, formatters, TabView, SimpleField, SingleSelect } from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import '../../../../../commonStyle.scss';
import '../../../Vehicles/ScheduleVehicle/scheduleVehicleStyle.scss';
import '../../../controllerStyle.scss';
import '../../../Notes/NotesandSticky.scss';
import moment from 'moment';
import { getJobDetailsById, getAllJobDropDowns, postJobDetails } from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import JobHistory from '../../../Vehicles/ScheduleVehicle/jobHistory';
import UpcomingEvents from '../../../Vehicles/ScheduleVehicle/upcomingEvents';
import StickyNotes from '../../../Notes/stickyNotes';
import { useHistory } from 'react-router-dom';
import JobDetailsInfo from '../../../Vehicles/ScheduleVehicle/showJobDetailsInfo';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import FileUploaderButton from '../../../../../../Core/Controls/GenericFileUploader';
import InternalstatusBasedButton from './statusBtn';
import JobQueriesScreen from '../../jobQueriesScreen';
import { ChipWithoutLable, CustomChip } from '../../../../../../Core/Controls/Inputs/DatePicker';
import WorkIcon from '@material-ui/icons/Work';
import BuildIcon from '@material-ui/icons/Build';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import AddVor from '../../../VOR/AddEditVor';
import AddIcon from '@material-ui/icons/Add';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import ServiceAgentDetails from '../../../DetailsModal/serviceAgent';
import JobUpdtaeNotes from './jobUpdateNotes';

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

const InternalJobDetail = (props) => {
    const [jobDetails, setJobDetails] = useState({
        jobDepotID: '',
        jobTypeID: '',
        mileageRequired: false,
        jobServiceAgentID: '',
        vehicleMake: '',
        vehicleModel: '',
        jobRegNumber: '',
        jobOutOfHours: false,
        showSaModal: false
    });
    const [jobDropDowns, setJobDropDowns] = useState({ serviceAgents: [], depots: [], jobTypes: [] });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const history = useHistory();
    const [tempOptions, setTempOptions] = useState({
        jobServiceAgentIDValue: null,
        jobDepotIDValue: null,
        jobTypeIDValue: null
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');
    const isCustomer = userRoles.includes('customer');
    const isServiceAgent = userRoles.includes('serviceagent');
    const pullJobDropDowns = async () => {
        let res = await getAllJobDropDowns(props.vehicleID);
        return res.data;
    };

    const PullData = async () => {
        let res = await getJobDetailsById(props.scheduleJobID);
        let res1 = res.data.list;
        let data = res1?.length > 0 ? res.data.list[0] : res.data.list;
        if (data) {
            setJobDetails((st) => ({
                ...st,
                jobID: data.jobID,
                jobAgentOrderNo: data.jobAgentOrderNo,
                jobOrderDate: formatters.DateOnlyFormatter(data.jobDate),
                jobOrderTotal: data.orderTotal,
                jobCustomerOrderNo: data.jobCustomerOrderNo || '',
                jobEstimatedCost: data.jobEstimatedCost || '',
                jobProformaInvNet: data.jobProformaInvNet || '',
                jobLimit: data.jobLimit || '',
                jobAuthorityRef: data.jobAuthorityRef || '',
                jobMileageDate: data.jobMileageDate || '',
                jobOutOfHours: data.jobOutOfHours || false,
                jobMileage: data.jobMileage || '',
                jobDepotID: data.jobDepotID || '',
                jobTypeID: data.jobTypeID || '',
                jobServiceAgentID: data.jobServiceAgentID || '',
                jobVehicleID: data.jobVehicleID,
                jobRegNumber: data.jobRegNumber,
                vehicleMake: data.vehicleMake,
                vehicleModel: data.vehicleModel,
                vehicleJobStatus: data.vehicleJobStatus,
                jobStatusID: data.jobStatusID,
                jobApprovedDate: formatters.DateFormatter(data.jobApprovedDate),
                approvedBy: data.approvedBy,
                jobApprovedOrderNo: data.jobApprovedOrderNo,
                jobApproved: data.jobApproved,
                serviceAgentName: data.serviceAgentName,
                depotName: data.depotName,
                mileageRequired: data.mileageRequired,
                customerName: data.customerCompanyName,
                refreshDependancy: new Date(),
                jobDescription: data.jobDescription,
                fileCount: data.fileCount,
                jobOrderNo: data.jobOrderNo,
                jobInCustomerQuery: data.jobInCustomerQuery,
                jobInAgentQuery: data.jobInAgentQuery,
                defectTypeCode: data.defectTypeCode,
                jobDefectID: data.jobDefectID,
                vorVehicleID: data.vorVehicleID,
                jobIsEstimate: data.jobIsEstimate,
                isEditableFields:
                    data.jobStatusID == 'AU' ||
                    data.jobStatusID == 'AA' ||
                    data.jobStatusID == 'JP' ||
                    data.jobStatusID == 'ER' ||
                    data.jobStatusID == 'JQ' ||
                    data.jobStatusID == 'IQ' ||
                    data.jobStatusID == 'AR' ||
                    data.jobStatusID == 'EA'
            }));
        }
    };

    useEffect(async () => {
        PullData();
        let dropDowns = await pullJobDropDowns();
        setJobDropDowns(dropDowns);
    }, []);

    const refreshScreen = (res) => {
        res && setJobDetails((st) => ({ ...st, refreshDependancy: new Date() }));
        res && PullData();
    };

    const showVorModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            [res]: true
        }));
    };

    const closeVORModal = (res) => {
        setJobDetails((st) => ({
            ...st,
            showVORPopup: false,
            showSaModal: false
        }));
        res && refreshScreen(true);
    };

    useEffect(() => {
        let newSt = {};

        if (jobDetails.jobServiceAgentID && jobDropDowns.serviceAgents?.length > 0) {
            newSt.jobServiceAgentIDValue = jobDropDowns.serviceAgents.find(({ id }) => id === jobDetails.jobServiceAgentID);
        }
        if (jobDetails.jobDepotID && jobDropDowns.depots?.length > 0) {
            newSt.jobDepotIDValue = jobDropDowns.depots.find(({ id }) => id === jobDetails.jobDepotID);
        }
        if (jobDetails.jobTypeID && jobDropDowns.jobTypes?.length > 0) {
            newSt.jobTypeIDValue = jobDropDowns.jobTypes.find(({ id }) => id === jobDetails.jobTypeID);
        }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [jobDetails.jobServiceAgentID, jobDetails.jobDepotID, jobDropDowns?.depots, jobDetails?.jobTypeID, jobDropDowns?.jobTypes, jobDropDowns?.serviceAgents]);
    const tempTab = [
        {
            label: 'Update Notes',
            body: (
                <JobUpdtaeNotes
                    orderNo={jobDetails?.jobAgentOrderNo}
                    regNo={jobDetails.jobRegNumber}
                    vehicleID={props.vehicleID}
                    jobID={props.scheduleJobID}
                    height={360}
                />
            )
        },
        {
            label: 'Job Queries',
            body: (
                <JobQueriesScreen
                    VehicleID={props.VehicleID}
                    JobID={props.scheduleJobID}
                    fromPage="job"
                    isHeaderNotShow={true}
                    height={360}
                    refreshDependancy={jobDetails.refreshDependancy}
                />
            )
        },
        {
            label: 'Upcoming Events',
            body: <UpcomingEvents vehicleID={props.vehicleID} refreshDependancy={jobDetails.refreshDependancy} jobID={props.scheduleJobID} height={360} />
        },
        {
            label: 'History',
            body: <JobHistory jobID={props.scheduleJobID} refreshDependancy={jobDetails.refreshDependancy} height={360} />
        }
    ];

    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!tempOptions.jobDepotIDValue) {
            err.jobDepotID = true;
            noErrors = false;
        }
        if (!tempOptions.jobTypeIDValue) {
            err.jobTypeID = true;
            noErrors = false;
        }
        if (!tempOptions.jobServiceAgentIDValue) {
            err.jobServiceAgentID = true;
            noErrors = false;
        }
        if (+jobDetails.jobLimit < 0) {
            err.jobLimit = true;
            noErrors = false;
        }
        if (+jobDetails.jobMileage < 0) {
            err.jobMileage = true;
            noErrors = false;
        }

        setErrors(err);
        return noErrors;
    };

    const handleData = async () => {
        if (validate()) {
            let response = await postJobDetails({
                jobID: props.scheduleJobID,
                jobServiceAgentID: tempOptions.jobServiceAgentIDValue?.id,
                jobDepotID: tempOptions.jobDepotIDValue?.id,
                jobTypeID: tempOptions.jobTypeIDValue?.id,
                jobCustomerOrderNo: jobDetails.jobCustomerOrderNo || null,
                jobEstimatedCost: jobDetails.jobEstimatedCost || null,
                jobProformaInvNet: jobDetails.jobProformaInvNet || null,
                jobLimit: jobDetails.jobLimit || null,
                jobAuthorityRef: jobDetails.jobAuthorityRef || null,
                jobMileage: jobDetails.jobMileage || null,
                jobMileageDate: jobDetails.jobMileageDate || null,
                jobOutOfHours: jobDetails.jobOutOfHours || false
            });
            if (response) {
                response.success ? showToastSuccess('Updated Job Details Successfully') : showToastError(response.message || 'Something went wrong');
                refreshScreen(true);
            } else {
                showToastError('Something went wrong');
            }
        }
    };

    const valueChanged = (ev) => {
        let temp = ev.target;
        setJobDetails((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    const radioButtonChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setJobDetails((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setJobDetails((st) => ({ ...st, [name]: false }));
        }
    }, []);

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const goPreviousScreen = () => {
        history.goBack();
    };

    const handleEditDefect = (val) => {
        if (jobDetails.defectTypeCode == 'DD') {
            history.push({
                pathname: `/maintenance/defect/edit/${jobDetails.jobDefectID}/${jobDetails.jobVehicleID}`,
                state: 'Edit'
            });
        } else {
            history.push({
                pathname: `/maintenance/breakdowns/Edit/${jobDetails.jobDefectID}/${jobDetails.jobVehicleID}`,
                state: 'Edit'
            });
        }
    };

    const getMakeModel =
        jobDetails.vehicleMake || jobDetails.vehicleModel
            ? ` (${jobDetails.vehicleMake ? jobDetails.vehicleMake : ''} ${jobDetails.vehicleModel ? jobDetails.vehicleModel : ''})`
            : '';
    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${props.vehicleID}`,
            search: ''
        });
    };
    return (
        <div className="controller-screen" style={{ padding: '1px' }}>
            <StickyNotes frompage="Jobs" noteRefsGuidID={props.scheduleJobID} />
            <Grid container className="mt_10">
                <Grid item xs={12} lg={12}>
                    <Grid item container xs={12} justify="flex-start" style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                        {jobDetails?.jobOrderNo ? (
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                                <FormHelperText shrink>Final Order No </FormHelperText>
                                <Typography variant="button">
                                    {jobDetails?.jobOrderNo || ''}({jobDetails?.jobAgentOrderNo})
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
                                <FormHelperText shrink>Job Reference</FormHelperText>
                                <Typography variant="button">{jobDetails?.jobAgentOrderNo || ''}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={6} sm={6} md={3} lg={2} xl={1}>
                            <FormHelperText shrink>Order Date</FormHelperText>
                            <Typography variant="button">{jobDetails.jobOrderDate}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={5} lg={2} xl={3}>
                            <FormHelperText shrink>Customer</FormHelperText>
                            <Typography variant="button">{jobDetails.customerName}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={2} xl={4}>
                            <FormHelperText shrink>Vehicle Detail</FormHelperText>
                            <Typography variant="button">
                                <span onClick={handleEditRegClick} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                    {jobDetails.jobRegNumber}
                                </span>
                                {getMakeModel}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={6} sm={4} md={2}>
                            <FormHelperText shrink>Job Status</FormHelperText>
                            <Typography variant="button">{jobDetails.vehicleJobStatus}</Typography>
                        </Grid> */}
                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <FormHelperText style={{ textAlign: 'end' }}>Total</FormHelperText>
                                <Typography variant="button" style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>
                                    £{jobDetails.jobOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} style={{ marginTop: 3 }}>
                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                        {jobDetails.jobDescription}
                    </Alert>
                </Grid> */}
                <Grid item xs={12} style={{ marginTop: 3 }}>
                    <Grid container spacing={1} className="alert-container">
                        <Grid item xs={12} sm={6} md={4}>
                            <Alert icon={false} severity={alertBoxColorCode[jobDetails.jobStatusID]} className="alert-items">
                                <span style={{ display: 'block', fontSize: '12px' }}>Status</span>
                                <span style={{ fontWeight: 'bold' }}>{jobDetails.vehicleJobStatus}</span>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                            <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                                <span style={{ display: 'block', fontSize: '12px' }}>Job Description</span>
                                <span style={{ fontWeight: 'bold' }}>{jobDetails.jobDescription}</span>
                            </Alert>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                    <Grid container item xs={12} lg={6}>
                        <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style grey-grid">
                            <Grid item>
                                <Typography variant="h5" color="secondary">
                                    Job Details
                                    <span style={{ position: 'relative', top: '-7px', left: '7px' }}>
                                        <FileUploaderButton
                                            recordId={props.scheduleJobID}
                                            fileUploaderConfigName="JobScreen"
                                            fileCount={jobDetails.fileCount}
                                            gridRelaod={() => refreshScreen(true)}
                                        />
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item style={{ padding: '2px 5px 6px 2px' }}>
                                <Grid container>
                                    {jobDetails.jobDefectID && (
                                        <Grid item>
                                            <ChipWithoutLable
                                                icon={BuildIcon}
                                                iconFontSize={16}
                                                onClick={handleEditDefect}
                                                className="btn-m"
                                                toolTipTitle={jobDetails.defectTypeCode == 'DD' ? 'Defect Details' : 'Breakdown Details'}
                                            />
                                        </Grid>
                                    )}
                                    {!jobDetails.vorVehicleID && !isDriver && !isCustomer && !isServiceAgent && (
                                        <ChipWithoutLable
                                            icon={AddIcon}
                                            toolTipTitle={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`}
                                            iconFontSize={16}
                                            className="btn-m"
                                            onClick={() => showVorModal('showVORPopup')}
                                        />
                                    )}
                                    <Grid item>
                                        {jobDetails.vehicleJobStatus && (
                                            <InternalstatusBasedButton
                                                refreshScreen={refreshScreen}
                                                handleData={handleData}
                                                vehicleID={props.vehicleID}
                                                position={props.position}
                                                status={jobDetails.jobStatusID}
                                                data={jobDetails}
                                                jobID={props.scheduleJobID ? props.scheduleJobID : jobDetails.jobID}
                                                goPreviousScreen={goPreviousScreen}
                                                showSave={checkhaveAccess?.CanUpdateJobs}
                                                btnType="chip"
                                                isShownHeadrer={true}
                                                otherOptionsBtnShow={checkhaveAccess?.CanUpdateJobs}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container style={{ paddingTop: '10px' }} className="grid-style grey-grid">
                            {jobDetails.jobStatusID == 'JC' && jobDetails.jobApproved ? (
                                <JobDetailsInfo jobDetails={jobDetails} />
                            ) : (
                                <Grid container spacing={2}>
                                    {!jobDetails.isEditableFields ? (
                                        <Grid item xs={12} md={8} className="input-style">
                                            <div className="input-style" style={{ cursor: 'pointer' }} onClick={() => showVorModal('showSaModal')}>
                                                <InputLabel shrink className="input_label">
                                                    Service Agent
                                                </InputLabel>
                                                <div className="input_value">{tempOptions.jobServiceAgentIDValue?.name}</div>
                                            </div>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sm={12} md={8} className="mandatory-fields">
                                            <SingleSelect
                                                inputLabel="Service Agent"
                                                isRequired={true}
                                                options={jobDropDowns.serviceAgents}
                                                value={tempOptions.jobServiceAgentIDValue}
                                                onChange={singleSelectChange('jobServiceAgentIDValue')}
                                                isDisabled={!jobDetails.isEditableFields}
                                            />
                                            <FormHelperText error>{errors.jobServiceAgentID ? 'This field is required' : null}</FormHelperText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                                        <SingleSelect
                                            inputLabel="Job Type"
                                            isRequired={true}
                                            options={jobDropDowns.jobTypes}
                                            value={tempOptions.jobTypeIDValue}
                                            onChange={singleSelectChange('jobTypeIDValue')}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobTypeID ? 'This field is required' : null}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} className="mandatory-fields">
                                        <SingleSelect
                                            inputLabel=" Job Depot"
                                            isRequired={true}
                                            options={jobDropDowns.depots}
                                            value={tempOptions.jobDepotIDValue}
                                            onChange={singleSelectChange('jobDepotIDValue')}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobDepotID ? 'This field is required' : null}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleField
                                            inputLabel="Order Limit"
                                            type="number"
                                            value={jobDetails.jobLimit || ''}
                                            name="jobLimit"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobLimit ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        {/* <InputLabel shrink>Job Mileage Date</InputLabel> */}
                                        <DatePicker
                                            inputLabel="Job Mileage Date"
                                            isRequired={true}
                                            inputProps={{
                                                max: new Date().toISOString().slice(0, 10)
                                            }}
                                            value={moment(jobDetails.jobMileageDate).format('YYYY-MM-DD')}
                                            name="jobMileageDate"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobMileageDate ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleField
                                            inputLabel="Job Mileage"
                                            type="number"
                                            value={jobDetails.jobMileage}
                                            name="jobMileage"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                        <FormHelperText error>{errors.jobMileage ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleField
                                            inputLabel="Customer Order No"
                                            value={jobDetails.jobCustomerOrderNo}
                                            name="jobCustomerOrderNo"
                                            onChange={valueChanged}
                                            isDisabled={!jobDetails.isEditableFields}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid container>
                                            <Grid container item xs={12}>
                                                <InputLabel shrink>Out Of Hours?</InputLabel>
                                            </Grid>
                                            <Grid container item spacing={1}>
                                                <RadioGroup
                                                    row
                                                    aria-label="jobOutOfHours"
                                                    name="jobOutOfHours"
                                                    value={jobDetails.jobOutOfHours}
                                                    onChange={radioButtonChange}
                                                >
                                                    <FormControlLabel
                                                        checked={jobDetails.jobOutOfHours === true}
                                                        value={true}
                                                        control={<Radio />}
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        checked={jobDetails.jobOutOfHours === false}
                                                        value={false}
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container item xs={12} style={{ paddingBottom: '5px' }}>
                                {jobDetails.vehicleJobStatus && (
                                    <InternalstatusBasedButton
                                        refreshScreen={refreshScreen}
                                        handleData={handleData}
                                        vehicleID={props.vehicleID}
                                        status={jobDetails.jobStatusID}
                                        data={jobDetails}
                                        jobID={props.scheduleJobID ? props.scheduleJobID : jobDetails.jobID}
                                        goPreviousScreen={goPreviousScreen}
                                        showSave={true}
                                        otherOptionsBtnShow={false}
                                    />
                                )}
                            </Grid>
                            {/* <br />
                            <Grid item xs={12}>
                                <JobItemsScreen scheduleJobID={props.scheduleJobID} />
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6} style={{ paddingTop: '0px' }}>
                        <Grid item xs={12}>
                            <TabView tabList={tempTab} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {jobDetails.showSaModal ? (
                <DialogComp open={true} onClose={() => closeVORModal(false)} title={'Service Agent Details'}>
                    <ServiceAgentDetails serviceAgentID={tempOptions.jobServiceAgentIDValue?.id} onFormSubmit={() => closeVORModal(false)} />
                </DialogComp>
            ) : null}
            {jobDetails.showVORPopup ? (
                <DialogComp title={`${jobDetails.vorVehicleID ? 'Update' : 'Add'} VOR`} onClose={() => closeVORModal(false)} maxWidth="lg" overflow="auto">
                    <AddVor
                        VehicleID={jobDetails.jobVehicleID}
                        update={jobDetails.vorVehicleID}
                        vorVehicleID={jobDetails.vorVehicleID}
                        jobID={jobDetails.jobID}
                        onClose={closeVORModal}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default InternalJobDetail;
