import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, FormControlLabel } from '@material-ui/core';
import { InputText, SecondaryButton, SelectBox, MandatoryField, SimpleField, Accordion, SingleSelect, Multiselect } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import { useToast } from '../../../../Core/Hooks/useToast';
import {
    getVehicleBreakdownDropdowns,
    postVehicleBreakdown,
    getVehicleBreakdownsbyID,
    getBreakDownDropDown
} from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RoomIcon from '@material-ui/icons/Room';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory } from 'react-router-dom';
import CustomizedSteppers from '../BreackdownDefectStepper/defectBreackDownStepper';
import { formatters } from '../../../../Core/Controls';
import AddIcon from '@material-ui/icons/Add';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import DialogComp from '../../../../Core/Dialog/DialogComp';
import JobStepperScreen from '../BreackdownDefectStepper/addJobStepper';
import BreakDownEditDetails from './BreakDownEditDetails';
import NoteIcon from '@material-ui/icons/Note';
import BreakDownDetails from './BreakDownDetails';
import GetAgentLocation from './getLocations';
import Mapheader from './locationHeaderComp';
import { Alert } from '@material-ui/lab';

let domainName = window.location.host;
const trimedhost = domainName.includes('localhost') ? 'localhost' : domainName;

const AddBreaddownModal = (props) => {
    console.log(props, 'ssss');
    const { showToastSuccess, showToastError, showToastWarning } = useToast();
    let history = useHistory();
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        defectID: null,
        breakDownType: 'BR',
        driverName: '',
        driverPhone: null,
        breakDownLocation: '',
        problemReported: '',
        replacementvehicleRequired: false,
        defectPG9Issued: false,
        mobilityUsed: 0,
        mobilityRequested: 0,
        reportingDepot: null,
        callOutRequired: false,
        defectVOR: false,
        policeOnScene: 0,
        passengersInvolved: false,
        accDamage: false,
        vehicleLoaded: false,
        urgentCallOut: false,
        policeOnScene: false,
        mobilityRequested: false,
        mobilityUsed: false,
        vehicleLoadTypesList: [],
        depotsList: [],
        vehicleJobProgress: [],
        errors: {},
        showJob: false,
        loadType: 1,
        showMap: false,
        loadTypeValue: '',
        isRefresh: '',
        defectFaultTypes: [],
        faultTypes: []
    });

    const [selectedData, setSelectedData] = useState({
        modules: []
    });

    const pullBreackdownById = async () => {
        let res = await getVehicleBreakdownsbyID(props.breakDownID);
        let data = res.data.details[0];
        let vehicleJobProgressDates = res.data.vehicleJobProgressDates;
        setState((st) => ({
            ...st,
            defectID: data.defectID,
            vehicleID: data.defectVehicleID,
            breakDownType: data.defectTypeCode,
            mileage: data.defectMileage,
            reportingDepot: data.defectReportingDepotID,
            breakDownLocation: data.defectLocation,
            reportedPhone: data.defectContactNumber,
            reportedBy: data.defectContactName,
            driverName: data.defectDriverName,
            driverPhone: data.defectDriverTelephone,
            problemReported: data.defectDescription,
            callOutRequired: data.defectCallOutRequired,
            urgentCallOut: data.defectUrgentCallOut,
            putOnVOR: data.defectVOR,
            policeOnScene: data.defectPoliceOnScene,
            passengersInvolved: data.defectPassengersInvolved,
            numberOfPassenger: data.defectNumberOfPassengers,
            accDamage: data.defectAccidentDamage,
            iNSREF: data.defectInsuranceReference,
            replacementvehicleRequired: data.defectReplacementVehicle,
            domainName: data.domainName,
            vehicleLoaded: data.defectVehicleLoaded,
            loadType: data.defectVehicleLoadTypeID,
            mobilityRequested: data.defectMobilityRequested,
            mobilityUsed: data.defectMobilityUsed,
            PG9Issued: data.defectPG9Issued,
            defectNumberGenerated: data.defectNumberGenerated,
            defectResolvedNotes: data.defectResolvedNotes,
            reportingDepotName: data.reportingDepot,
            vehicleLoadTypeDescription: data.vehicleLoadTypeDescription,
            defectRegNumber: data.defectRegNumber,
            customer: data.customer,
            customerID: data.customerID,
            reportingDepot: data.reportingDepot,
            make: data.make,
            model: data.model,
            vorVehicleID: data.vorVehicleID,
            defectFaultTypes: res.data.faultIds,
            //
            vehicleJobProgress: vehicleJobProgressDates,
            defectDateCreated: formatters.DateTimeFormatter(data.defectDateCreated),
            defectDateCallOutArrived: formatters.DateTimeFormatter(data.defectDateCallOutArrived),
            defectDateVehicleRecovered: formatters.DateTimeFormatter(data.defectDateVehicleRecovered),
            defectDateResolved: formatters.DateTimeFormatter(data.defectDateResolved),
            defectDateSignOff: formatters.DateTimeFormatter(data.defectDateSignOff),
            fileCount: data.fileCount,
            isRefresh: new Date()
        }));
    };

    useEffect(async () => {
        if (props.breakDownID) {
            pullBreackdownById();
        }
        if (props.VehicleID) {
            let res = await getVehicleBreakdownDropdowns(props.VehicleID);
            setState((st) => ({
                ...st,
                vehicleLoadTypesList: res.data.vehicleLoadTypes,
                loadTypeValue: res.data.vehicleLoadTypes[0],
                depotsList: res.data.depots,
                vehicleID: props.VehicleID,
                vehcileCategories: res.data.vehcileCategories[0].vehicleCategory,
                faultTypes: res.data.faultTypes,
                domainName: trimedhost
            }));
        } else {
            let res = await getBreakDownDropDown();
            setState((st) => ({
                ...st,
                vehicleLoadTypesList: res.data.vehicleLoadTypes,
                loadTypeValue: res.data.vehicleLoadTypes[0],
                depotsList: res.data.depots,
                vehcileCategories: res.data.vehcileCategories[0].vehicleCategory,
                faultTypes: res.data.faultTypes,
                domainName: trimedhost
            }));
        }
    }, []);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(state.driverName)) {
            errors.driverName = 'Driver Name is required';
            formIsValid = false;
        }
        if (!/^[0-9]*$/.test(state.driverPhone)) {
            errors.driverPhone = 'This field accepts number 1 - 0';
            formIsValid = false;
        }
        if (!state.reportingDepotValue) {
            errors.reportingDepot = 'Reporting depot is required';
            formIsValid = false;
        }
        if (!state.breakDownLocation) {
            errors.breakDownLocation = 'Location is required';
            formIsValid = false;
        }
        if (!state.reportedBy) {
            errors.reportedBy = 'Reported By field is required';
            formIsValid = false;
        }
        if (!state.reportedPhone) {
            errors.reportedPhone = 'Reported phone field is required';
            formIsValid = false;
        }
        if (!state.problemReported) {
            errors.problemReported = 'Problem reported field is required';
            formIsValid = false;
        }
        if (!state.loadTypeValue) {
            errors.loadType = 'Vehicle loaded type is required';
            formIsValid = false;
        }
        // if (state.defectFaultTypes.length <= 0) {
        //     errors.defectFaultTypes = 'This field is required';
        //     formIsValid = false;
        // }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    useEffect(() => {
        if (state.defectFaultTypes) {
            const selectedFaultTypes = state.faultTypes.filter((w) => state.defectFaultTypes.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                modules: selectedFaultTypes
            }));
        }
    }, [state.defectFaultTypes, state.faultTypes]);

    const ModuleChange = (e, value) => {
        setSelectedData((st) => ({ ...st, modules: value }));
        setState((st) => {
            const nst = { ...st, defectFaultTypes: value.map((c) => c.id) };
            return nst;
        });
    };

    const submitHandler = async () => {
        if (validations()) {
            if (state.defectID) {
                alert('Update is pending');
            } else {
                setBtnLoader(true);
                let res = await postVehicleBreakdown(state, selectedData);
                if (res.success) {
                    showToastSuccess(`Breakdown number ${res.data.defectNumber} generated successfully`);
                    history.push(`/maintenance/breakdowns/Edit/${res.data.defectID}/${props.VehicleID}`);
                    // props.onClose(true);
                } else {
                    console.log(res.message);
                    showToastError('res.message');
                    showToastWarning('res.message');
                }
                setBtnLoader(false);
            }
        }
    };

    const handleShowLocation = (id) => {
        setState((st) => ({ ...st, showMap: true }));
    };

    const handleUpdate = () => {
        setState((st) => ({ ...st, showJob: true }));
    };

    const closetModal = (res) => {
        setState((st) => ({ ...st, showJob: false, showMap: false }));
        res && pullBreackdownById();
    };

    const handleUpdateLocation = (location) => {
        setState((st) => ({ ...st, breakDownLocation: location, showMap: false }));
    };

    return (
        <div style={{ padding: 5 }}>
            {props.frompage === 'Breakdown' && state.defectNumberGenerated ? (
                <BreakDownDetails
                    data={state}
                    isAddJobBtnShow={props.isAddJobBtnShow}
                    pullBreackdownById={pullBreackdownById}
                    onClose={() => props.onClose()}
                />
            ) : null}
            {/* {state.defectNumberGenerated && !props.isAddJobBtnShow ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomIconButton icon={AddIcon} toolTipTitle="Add Job" iconFontSize={16} onClick={handleUpdate} className="btn-m">
                        &nbsp; Add Job
                    </CustomIconButton>
                </div>
            ) : null} */}
            {state.showJob ? (
                <DialogComp title="Add Job" onClose={() => closetModal(false)} maxWidth="sm">
                    <JobStepperScreen onClose={closetModal} userData={state} />
                </DialogComp>
            ) : null}
            {state.showMap ? (
                <DialogComp title={<Mapheader />} onClose={() => closetModal(false)} maxWidth="lg" fullScreen>
                    <GetAgentLocation location={state.breakDownLocation} updateLocation={handleUpdateLocation} vehcileCategories={state.vehcileCategories} />
                </DialogComp>
            ) : null}

            {props.modalName === 'Edit' ? (
                <Grid container spacing={1}>
                    {state.vehicleJobProgress?.map((a, index) => (
                        <Grid item xs={12} key={index}>
                            <CustomizedSteppers data={a} userData={state} onStepperSubmit={pullBreackdownById} fromPage={true} />
                        </Grid>
                    ))}

                    <Grid container>
                        <BreakDownEditDetails data={state} onClose={props.onClose} pullBreackdownById={pullBreackdownById} />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6} md={4} lg={3} item>
                        <Grid container>
                            <Grid item xs={12}>
                                <InputLabel shrink>Breakdown Type?</InputLabel>
                            </Grid>
                            <Grid container item spacing={1}>
                                <RadioGroup row aria-label="DefectCallOutRequired" name="breakDownType" value={state.breakDownType} onClick={inputChange}>
                                    <FormControlLabel value="BR" control={<Radio />} label="Road Side" />
                                    <FormControlLabel value="BO" control={<Radio />} label="On Site" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        {/* <MandatoryField
                    inputLabel="Reporting Depot"
                    name="reportingDepot"
                    value={state.reportingDepot}
                    onChange={inputChange}
                    List={state.depotsList}
                    errors={state.errors.reportingDepot}
                /> */}
                        <InputLabel required shrink>
                            Reporting Depot
                        </InputLabel>
                        {/* <SelectBox name="reportingDepot" value={state.reportingDepot} onChange={inputChange} List={state.depotsList || []} /> */}
                        <SingleSelect options={state.depotsList} value={state.reportingDepotValue} onChange={singleSelectChange('reportingDepotValue')} />
                        <FormHelperText error>{state.errors.reportingDepot}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Breakdown Location"
                            placeholder="Breakdown Location"
                            value={state.breakDownLocation}
                            onChange={inputChange}
                            name="breakDownLocation"
                            errors={state.errors.breakDownLocation}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CustomIconButton
                                            toolTipTitle="Get location"
                                            onClick={() => {
                                                handleShowLocation(state.defectID);
                                            }}
                                            icon={RoomIcon}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />

                        {/* <FormHelperText error>{state.errors.breakDownLocation}</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField inputLabel="Mileage" placeholder="Mileage" type="number" value={state.mileage} name="mileage" onChange={inputChange} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Reported By"
                            placeholder="Reported By"
                            value={state.reportedBy || ''}
                            onChange={inputChange}
                            name="reportedBy"
                            errors={state.errors.reportedBy}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Reported Phone"
                            placeholder="Reported Phone"
                            value={state.reportedPhone}
                            onChange={inputChange}
                            name="reportedPhone"
                            errors={state.errors.reportedPhone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Driver Name"
                            placeholder="Driver Name"
                            value={state.driverName}
                            onChange={inputChange}
                            name="driverName"
                            errors={state.errors.driverName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Driver Phone"
                            placeholder="Driver Phone"
                            value={state.driverPhone}
                            onChange={inputChange}
                            name="driverPhone"
                            errors={state.errors.driverPhone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Problem Reported"
                            multiline
                            rows={3}
                            placeholder="Problem Reported"
                            value={state.problemReported}
                            onChange={inputChange}
                            name="problemReported"
                            errors={state.errors.problemReported}
                        />
                    </Grid>

                    <Grid item xs={12} className="mandatory-fields">
                        <InputLabel InputLabel="" shrink required>
                            Fault Category
                        </InputLabel>
                        <Multiselect options={state.faultTypes} value={selectedData.modules} onChange={ModuleChange} disabled={state.isDisable} />
                        <FormHelperText error>{state.errors.defectFaultTypes}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Accordion header={'Additional Details'}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Call Out Required</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup
                                                row
                                                aria-label="callOutRequired"
                                                name="callOutRequired"
                                                value={state.callOutRequired}
                                                onChange={inputChange}
                                            >
                                                <FormControlLabel checked={state.callOutRequired === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.callOutRequired === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Urgent Call Out</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup row aria-label="urgentCallOut" name="urgentCallOut" value={state.urgentCallOut} onChange={inputChange}>
                                                <FormControlLabel checked={state.urgentCallOut === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.urgentCallOut === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Put On VOR</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup row aria-label="defectVOR" name="defectVOR" value={state.defectVOR} onChange={inputChange}>
                                                <FormControlLabel checked={state.defectVOR === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.defectVOR === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Replacement vehicle Required?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup
                                                row
                                                aria-label="replacementvehicleRequired"
                                                name="replacementvehicleRequired"
                                                value={state.replacementvehicleRequired}
                                                onChange={inputChange}
                                            >
                                                <FormControlLabel
                                                    checked={state.replacementvehicleRequired === true}
                                                    value={true}
                                                    control={<Radio />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    checked={state.replacementvehicleRequired === false}
                                                    value={false}
                                                    control={<Radio />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Passengers Involved?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup
                                                row
                                                aria-label="passengersInvolved"
                                                name="passengersInvolved"
                                                value={state.passengersInvolved}
                                                onChange={inputChange}
                                            >
                                                <FormControlLabel checked={state.passengersInvolved === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.passengersInvolved === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <SimpleField
                                        inputLabel="Number Of Passengers"
                                        placeholder="Number Of Passengers"
                                        value={state.numberOfPassenger}
                                        onChange={inputChange}
                                        name="numberOfPassenger"
                                    />
                                    <FormHelperText error>{state.errors.numberOfPassenger}</FormHelperText>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Accident Damage?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup row aria-label="accDamage" name="accDamage" value={state.accDamage} onChange={inputChange}>
                                                <FormControlLabel checked={state.accDamage === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.accDamage === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <SimpleField
                                        inputLabel="Insurance Reference"
                                        placeholder="Insurance Reference"
                                        value={state.iNSREF}
                                        onChange={inputChange}
                                        name="iNSREF"
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Police On Scene?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup row aria-label="policeOnScene" name="policeOnScene" value={state.policeOnScene} onChange={inputChange}>
                                                <FormControlLabel checked={state.policeOnScene === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.policeOnScene === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Vehicle Loaded?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup row aria-label="vehicleLoaded" name="vehicleLoaded" value={state.vehicleLoaded} onChange={inputChange}>
                                                <FormControlLabel checked={state.vehicleLoaded === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.vehicleLoaded === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                                    <InputLabel required shrink>
                                        Load Type
                                    </InputLabel>
                                    {/* <SelectBox name="loadType" value={+state.loadType} onChange={inputChange} List={state.vehicleLoadTypesList} /> */}
                                    <SingleSelect
                                        options={state.vehicleLoadTypesList}
                                        value={state.loadTypeValue}
                                        onChange={singleSelectChange('loadTypeValue')}
                                    />
                                    , falultVal:''{' '}
                                </Grid>
                                , falultVal:''
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Mobility Requested?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup
                                                row
                                                aria-label="mobilityRequested"
                                                name="mobilityRequested"
                                                value={state.mobilityRequested}
                                                onChange={inputChange}
                                            >
                                                <FormControlLabel checked={state.mobilityRequested === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.mobilityRequested === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>Mobility Used?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup row aria-label="mobilityUsed" name="mobilityUsed" value={state.mobilityUsed} onChange={inputChange}>
                                                <FormControlLabel checked={state.mobilityUsed === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.mobilityUsed === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={3} item>
                                    <Grid container>
                                        <Grid container item xs={12}>
                                            <InputLabel shrink>PG9 Issued?</InputLabel>
                                        </Grid>
                                        <Grid container item spacing={1}>
                                            <RadioGroup
                                                row
                                                aria-label="defectPG9Issued"
                                                name="defectPG9Issued"
                                                value={state.defectPG9Issued}
                                                onChange={inputChange}
                                            >
                                                <FormControlLabel checked={state.defectPG9Issued === true} value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel checked={state.defectPG9Issued === false} value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>
                    <Grid container>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={submitHandler} isBtnLoader={btnLoader}>
                                    {props.breakDownID ? 'Save' : 'Add'}
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default AddBreaddownModal;
