import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { ExpBaseURL } from '../../Basic/HttpService';
import { AppStorage } from '../../Basic/storage-service';
import './gijgoGrid.scss';
import FileUploaderButton from '../GenericFileUploader'; //'../GenericFileUploader';
import { isValidConfig } from '../GenericFileUploader/FileUploaderConfig';
import ReactDOM from 'react-dom';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import { useToast } from '../../Hooks/useToast';
import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters } from '../';
import { CustomChip, ChipWithoutLable } from '../Inputs/DatePicker';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';

var grid1;
var $ = window.$;

const GijgoGrid = (props) => {
    let isAction = props.columns.find((c) => c.key === 'action');

    const useStyles = makeStyles((theme) => ({
        gijgoGrid: {
            '& .gj-grid-md th:first-of-type': {
                padding: (props) => {
                    return props.isAction ? '12px 5px 12px 5px' : '12px 18px 12px 13px';
                }
            }
        }
    }));

    // useEffect(() => {
    //     var timer = setInterval(() => {
    //         setTime(new Date());
    //     }, 3000);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    const classes = useStyles({ isAction: isAction });
    let token = AppStorage.getToken();
    const ut = useToast();
    const uli = useLoginInfo();
    const [state, setState] = useState({
        recordId: props.recordId,
        fileCount: `${props.fileCount || 0}`,
        configName: props.fileUploaderConfigName,
        columns: []
    });
    const [gridReload, setGridReload] = useState(new Date());

    const gridFileCountRefresh = () => {
        setGridReload((st) => ({ ...st, gridReload: new Date() }));
    };
    useEffect(() => {
        console.log('Gijgo,1');
        let finalColumns = [];
        finalColumns = props.columns.map((c) => {
            if (c.key === 'action') {
                isAction = true;
                return {
                    events: c.events,
                    field: c.key,
                    title: c.name,
                    width: c.width,
                    renderer: (value, row) => (c.formatter ? c.formatter(value, row) : value),
                    addFileUploaderIcon: c.addFileUploaderIcon
                    // filterable: false
                };
            } else {
                return {
                    events: c.events,
                    field: c.key,
                    title: c.name,
                    width: c.width,
                    renderer: (value, row) => (c.formatter ? c.formatter({ row, column: { key: c.key, name: c.name } }, { isGijgo: true }) : value),
                    filterable: c.filterable === 'no' ? false : true,
                    sortable: c.sortable === true || c.sortable === undefined ? true : false,
                    align: c.align || 'left'
                };
            }
        });

        let cols = finalColumns.map((c) => ({ ...c, headerCssClass: 'gridHeader' }));
        if (cols.length > 0 && props.idFieldName && props.fileUploaderConfigName && isValidConfig(props.fileUploaderConfigName)) {
            const actionCol = cols.find((c) => c.addFileUploaderIcon);
            if (actionCol) {
                const prevFor = actionCol.renderer;
                var newFormatter = (params, val) => {
                    const spn2 = document.createElement('span');
                    const lnk2 = (
                        <Grid
                            container
                            // justify="space-evenly"
                            alignItems="center"
                        >
                            <Grid item style={{ marginRight: '12px', marginLeft: '5px' }}>
                                {
                                    <FileUploaderButton
                                        ut={ut}
                                        uli={uli}
                                        fileCount={val.fileCount}
                                        recordId={val[props.idFieldName]}
                                        fileUploaderConfigName={props.fileUploaderConfigName}
                                        gridRelaod={gridFileCountRefresh}
                                        fileUploaderModalClose={props.fileUploaderModalClose}
                                        docIDPassed={props.idFieldName == 'scheduleDocID' ? true : false}
                                    />
                                }
                            </Grid>
                            <Grid item>{prevFor(params, val)}</Grid>
                        </Grid>
                    );
                    ReactDOM.render(lnk2, spn2);
                    return spn2;
                };
                actionCol.renderer = newFormatter;
            }
        } else {
            const actionCol = cols.find((c) => c.field == 'action');
            if (actionCol) {
                const prevFor = actionCol.renderer;
                var newFormatter = (params, val) => {
                    const spn2 = document.createElement('span');
                    const lnk2 = prevFor(params, val);
                    ReactDOM.render(lnk2, spn2);
                    return spn2;
                };
                actionCol.renderer = newFormatter;
            }
        }
        setState((st) => ({ ...st, columns: cols }));
    }, [props.columns, gridReload]);

    useEffect(async () => {
        if (state.columns.length > 0) {
            console.log('Gijgo,2');
            pullArchivedJobsAndUpdateState();
        }
    }, [state.columns, props.isReload, props.getUrl]);

    const pullArchivedJobsAndUpdateState = async () => {
        window.$(`#${state.id}`).grid('destroy', true, true);

        setTimeout(() => {
            // storedFiltterValue = JSON.parse(localStorage.getItem('FiltterParams')) || {};
            if (props.filterVal) {
                let storedFiltterValue = {
                    vehicleRegNumber: props.filterVal.vehicleRegNumber || '',
                    customerCompanyName: props.filterVal.customerCompanyName || '',
                    depotName: props.filterVal.depotName || '',
                    vorTypeDescription: props.filterVal.vorTypeDescription || ''
                };
                props.columns.forEach((itm) => {
                    if (storedFiltterValue) {
                        var id = $(grid1).attr('id');
                        var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
                        var filterRow = $(rowXPath);
                        if ($(filterRow).length == 1) {
                            var colXPath = 'th [data-field="' + `${itm.key}` + '"]';
                            var filterInput = $(filterRow).find(colXPath);
                            if ($(filterInput).length && storedFiltterValue[`${itm.key}`]) {
                                $(filterInput).val(storedFiltterValue[`${itm.key}`]);
                                grid1.data().params[itm.key] = storedFiltterValue[`${itm.key}`];
                            }
                        }
                    }
                });
            }
            // if (storedFiltterValue.sortBy && storedFiltterValue.direction) {
            //     grid1.data().params.sortBy = storedFiltterValue.sortBy;
            //     grid1.data().params.direction = storedFiltterValue.direction;
            // }
            grid1.reload();
        });

        const onSuccessFunc = function (response) {
            grid1.render(props.dataConverter(response));
        };

        grid1 = window.$(`#${state.id}`).grid({
            dataSource: {
                url: `${ExpBaseURL}/api/${props.getUrl}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            },
            ...(props.minWidth !== null && { minWidth: 1200 }),
            // fixedHeader: true,
            headerFilter: {
                type: 'onchange'
            },

            columns: [...state.columns],
            pager: {
                limit: 20
            }
        });

        grid1.on('dataBound', function (e, records, totalRecords) {
            const gridActionSpn = document.createElement('span');
            const gridActionGrid = (
                <Grid container justify="space-around">
                    {props.btnTitle && (
                        <Grid item className="height-45">
                            {/* <Tooltip title={props.btnTitle} arrow> */}
                            <ChipWithoutLable icon={AddIcon} onClick={props.onBtnClick} style={{ fontWeight: 'bold' }} toolTipTitle={props.btnTitle} />
                            {/* <AddButton toolTipTitle="Add Convict" onClick={props.onBtnClick} /> */}
                            {/* </Tooltip> */}
                        </Grid>
                    )}
                    {props.secondBtnTitle && (
                        <Grid item className="height-45">
                            {/* <Tooltip title={props.btnTitle} arrow> */}
                            <ChipWithoutLable
                                icon={props.secondBtnIcon}
                                onClick={props.onSecondBtnClick}
                                style={{ fontWeight: 'bold' }}
                                toolTipTitle={props.secondBtnTitle}
                            />
                            {/* <AddButton toolTipTitle="Add Convict" onClick={props.onBtnClick} /> */}
                            {/* </Tooltip> */}
                        </Grid>
                        // <Grid item className="height-45">
                        //     <Tooltip title={props.secondBtnTitle} arrow>
                        //         <CustomChip
                        //             icon={props.secondBtnIcon}
                        //             label={props.secondBtnLabel}
                        //             onClick={props.onSecondBtnClick}
                        //             style={{ fontWeight: 'bold' }}
                        //         />
                        //     </Tooltip>
                        // </Grid>
                    )}
                    {props.mapBtnToolTipTitle && (
                        <Grid item className="height-45">
                            <ChipWithoutLable
                                icon={props.mapBtnIcon}
                                onClick={props.onMapBtnClick}
                                style={{ fontWeight: 'bold' }}
                                toolTipTitle={props.mapBtnToolTipTitle}
                            />
                        </Grid>
                    )}
                </Grid>
            );
            ReactDOM.render(gridActionGrid, gridActionSpn);
            var gridActionSpnRef = gridActionSpn;

            GijgoActionBtn(grid1, 'action', gridActionSpnRef);
        });
        grid1.on('rowDataBound', function (e, $row, id, record) {
            props.onRowDataBound && props.onRowDataBound(e, $row, id, record);
        });
    };

    const GijgoActionBtn = (grid, propertyName, dropdown) => {
        var id = $(grid).attr('id');
        var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
        var filterRow = $(rowXPath);
        if ($(filterRow).length == 1) {
            var colXPath = 'th [data-field="' + `action` + '"]';
            var filterInput = $(filterRow).find(colXPath);
            if ($(filterInput).length) {
                $(filterInput).replaceWith(dropdown);
            }
        }
    };
    const downloadCSVWrapper = async () => {
        if (props.downloadCustomUrl) {
            props.downloadCustomUrl(grid1.data().params);
        } else {
            grid1?.downloadCSV(`${props.downloadBtnName}-${moment().format('DD-MMM-YYYY HH:mm')}.csv`);
        }
    };
    return (
        <Grid
            container
            // style={{ position: 'relative' }}
        >
            <Grid container item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }} className={classes.gijgoGrid}>
                {props.downloadBtnName && (
                    <Grid
                        item
                        // style={{ top: '23px', left: '18px', zIndex: 99, position: 'absolute' }}
                        style={{ bottom: '3px', left: '16px', zIndex: 99, position: 'absolute' }}
                    >
                        <Tooltip title={`${props.downloadBtnName ? props.downloadBtnName : 'Download CSV'}`} arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
                <table id={`${state.id}`}></table>
            </Grid>
        </Grid>
    );
};

export default GijgoGrid;
