import React, { useEffect, useMemo, useState } from 'react';
// import AddEditDefectModal from './ServiceAgentAddEditDefect'; //'./addEditDefectScreen';
import AddEditDefectModal from '../../InternalUser/Maintenance/Defects/addEditDefectScreen'; //'./addEditDefectScreen';
import DataGrid from '../../../Core/Controls/Grid';
import { getAllDefects } from '../../../Core/Services/InternalUser/Maintenance/DefectsService';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton, SecondaryButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { Link } from 'react-router-dom';
import DefectEditDetails from '../../InternalUser/Maintenance/Defects/DefectEditDetails';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../Core/Basic/storage-service';
import { getVechicleIDByRegNO } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Maintenance', active: true },
    { name: 'Defects', path: '/', active: true }
];

const baseColumns = [
    { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    { key: 'defectNumberGenerated', name: 'Defect No', width: 80 },
    { key: 'customer', name: 'Customer', width: 80 },
    // { key: 'agent', name: 'Service Agent', width: 80 },
    {
        key: 'eta',
        name: 'ETA ',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    {
        key: 'defectDate',
        name: 'Date ',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    { key: 'defectDescription', name: 'Defect Details' },
    { key: 'defectContactName', name: 'Reported By', width: 150 },
    { key: 'defectJobStatusDescription', name: 'Status', width: 200 }
];

const ServiceAgentDefectsScreen = (props) => {
    let history = useHistory();
    const [state, setState] = useState({
        modalToggle: false,
        rowVehicleID: null,
        defectID: null,
        List: [],
        showLoader: true,
        fromPage: 'Defects',
        filterVal: '',
        show: false
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    useEffect(async () => {
        if (props.frompage === 'Vehicles') {
            setState((st) => ({
                ...st,
                fromPage: props.frompage
            }));
        }
        pullDefects();
    }, []);

    const pullDefects = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };
    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.defectJobID}/${val?.defectVehicleID}`,
            state: 'defects'
        });
    };

    const handleEditDefect = (val) => {
        props.history.push({
            pathname: `/maintenance/defect/edit/${val?.defectID}/${val?.defectVehicleID}`,
            state: 'Edit'
        });
    };

    const renderEdit = (data, row) => {
        return (
            <Grid item>
                <CustomIconButton
                    icon={BuildIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    onClick={() => handleEditDefect(row)}
                    toolTipTitle="Edit Defect"
                />
                {/* <EditButton toolTipTitle="Edit Defect" onClick={() => handleUpdate(row.defectID, row.defectVehicleID, 'modalEditDetail')} /> */}

                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton
                        icon={WorkIcon}
                        color="secondary"
                        className="modal-btn-update btn_space"
                        onClick={() => handleEditClick(row)}
                        toolTipTitle="Job Details"
                    />
                )}
            </Grid>
        );
    };

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = resBrekDown.success;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                    } else {
                        newSt.show = false;
                    }

                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }
        if (props.frompage === 'Vehicles') {
            setState((st) => ({
                ...st,
                fromPage: props.frompage
            }));
        }
        // pullDefects();
    }, [props.VehicleID, state.filterVal]);

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (!props.VehicleID) {
            tempCols.splice(2, 0, { key: 'defectRegNumber', name: 'Reg Number', width: 100 });
        }
        // if (window.innerWidth > 600) {
        //     tempCols.splice(
        //         6,
        //         0,
        //         { key: 'defectDriverName', name: 'Driver Name', width: 110 },
        //         { key: 'defectDriverTelephone', name: 'Driver Phone', width: 120 }
        //     );
        // }
        return tempCols;
    }, []);

    const handleUpdate = (defectID, VehicleID, modalName) => {
        setState((state) => ({ ...state, defectID: defectID, rowVehicleID: VehicleID, [modalName]: true }));
    };

    const closeModalHandler = (res, modalName) => {
        setState((state) => ({ ...state, [modalName]: false }));
        res && pullDefects();
    };

    // const gridBtnTitle = !props.VehicleID ? '' : 'Add Defect';

    const gridBtnTitle = state.show || props.VehicleID ? 'Add Defect' : null;

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    const isPage = !(props.VehicleID || props.customerID);

    return (
        <div className={!props.VehicleID ? `screen` : ''} style={{ marginTop: 8 }}>
            {isPage && (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                    <div style={{ width: 'auto' }}>
                        <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                    </div>
                    &nbsp;&nbsp;
                    {checkhaveAccess.CanAddDefects && (
                        <div style={{ width: 'auto' }}>
                            <input type="text" onChange={inputChange} placeholder="Enter Reg Number" style={{ padding: 5 }} />
                        </div>
                    )}
                    {gridBtnTitle && (
                        <div style={{ marginLeft: '4px' }}>
                            <SecondaryButton
                                style={{ padding: '2px 16px' }}
                                onClick={() => handleUpdate(null, state.filterVehicleID, 'modalToggle')}
                                fullWidth
                                // className="submit_btn"
                            >
                                Add
                            </SecondaryButton>
                        </div>
                    )}
                </div>
            )}

            {state.modalToggle ? (
                <AddEditDefectModal
                    defectID={state.defectID}
                    VehicleID={props.VehicleID || state.rowVehicleID}
                    onClose={() => closeModalHandler(true, 'modalToggle')}
                    fromPage={state.fromPage}
                    isAddJobBtnShow={true}
                />
            ) : state.modalEditDetail ? (
                <DefectEditDetails
                    defectID={state.defectID}
                    VehicleID={props.VehicleID || state.rowVehicleID}
                    onClose={() => closeModalHandler(true, 'modalEditDetail')}
                    fromPage={state.fromPage}
                />
            ) : (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl="VehicleDefects"
                    // btnTitle={gridBtnTitle}
                    idFieldName="defectID"
                    fileUploaderConfigName="DefectScreen"
                    // onBtnClick={() => handleUpdate(null, state.filterVehicleID, 'modalToggle')}
                    isReload={state.isReload}
                />
            )}
        </div>
    );
};

export default ServiceAgentDefectsScreen;
