import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../../../../Core/Controls/Grid';
import { getAllBookingSchedule } from '../../../../Core/Services/ServiceAgent/Schedule/BookingReq';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import { Link } from 'react-router-dom';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import WorkIcon from '@material-ui/icons/Work';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
// import ScheduleNotes from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleNotes';
// import ServiceAgentScheduleBookingSection from './BookingSection';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import NoteIcon from '@material-ui/icons/Note';
import ScheduleNotes from '../../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleNotes';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const baseColumns = [
    // { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    // { key: 'vehicleRegNumber', name: 'Reg Number' },
    // { key: 'ScheduleRequestedBookingDate', name: 'Booking Date', formatter: formatters.Date_DDMMYYYY },
    // { key: 'scheduleDateDue', name: 'Date Due', formatter: formatters.Date_DDMMYYYY },
    // { key: 'scheduleEventName', name: 'Event' },
    // { key: 'customerCompanyName', name: 'Customer' },
    // { key: 'depotName', name: 'Depot' }
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'scheduleRequestedBookingDate', name: 'Requested', formatter: formatters.Date_DDMMYYYY },
    { key: 'agentOrderNo', name: 'Job Ref', width: 150 },
    { key: 'scheduleDateDue', name: 'Due', formatter: formatters.Date_DDMMYYYY },
    { key: 'scheduleRequestedBookingTimeSlot', name: 'Time Slot' },
    { key: 'scheduleEventName', name: 'Event' },
    { key: 'scheduleNightBooking', name: 'Night Booking' },
    { key: 'scheduleDeliverBefore', name: 'Deliver Before' },
    { key: 'scheduleCollectAfter', name: 'Collect After' },
    { key: 'vehicleRegNumber', name: 'Reg Number' },
    { key: 'customerCompanyName', name: 'Customer' },
    { key: 'depotName', name: 'Depot' }
];

const AcceptRejectByAgent = (props) => {
    const [state, setState] = useState({ modalToggle: false, rowVehicleID: null, defectID: null, List: [], showLoader: true, isReload: null });
    const [openDialog, setOpenDialog] = useState({});
    const isDirectLink = window.location.pathname.includes('bookingaccepted') ? 2 : 3;
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Schedule' },
        { name: isDirectLink == 2 ? 'Booking Accepted' : 'Booking Rejected', path: '/', active: true }
    ];

    useEffect(async () => {
        pullAllBookingSchedule();
    }, [isDirectLink]);

    const pullAllBookingSchedule = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const checkButton = (ev, label) => {
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: ev,
            scheduleVehicleID: ev.scheduleVehicleID
        }));
    };

    const closeTheDialog = (res) => {
        setOpenDialog({});
        res && pullAllBookingSchedule();
    };

    const renderEdit = (data, row) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {row?.displayNotes === 'Y' && (
                    <CustomIconButton
                        icon={NoteIcon}
                        color="primary"
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'notes');
                        }}
                        toolTipTitle="Notes"
                    />
                )}
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);
    let getUrl = `ServiceAgentSchedules?VehicleID=&Status=${isDirectLink}`;
    return (
        <div className={!props.VehicleID ? `screen` : ''} style={{ marginTop: 8 }}>
            {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}

            {state.modalToggle ? (
                <h6>Update popup</h6>
            ) : (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    // btnTitle={gridBtnTitle}
                    // onBtnClick={jobHandler}
                    isReload={state.isReload}
                    idFieldName="scheduleID"
                    fileUploaderConfigName="ScheduleDocumentsRequired"
                    downloadBtnName={isDirectLink == 2 ? 'Booking Accepted' : 'Booking Rejected'}
                />
            )}
            {openDialog.notes ? (
                <DialogComp title="Schedule Notes" maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleNotes vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default AcceptRejectByAgent;
