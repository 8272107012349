import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    InputLabel,
    FormHelperText,
    Typography,
    InputAdornment,
    IconButton,
    Tooltip,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Popper,
    Popover,
    withStyles,
    TableHead,
    Paper,
    TableContainer
} from '@material-ui/core';
import { SecondaryButton, EditButton, DeleteButton, formatters, MandatoryField, SingleSelect, SimpleField } from '../../../../../../Core/Controls';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import '../../../../../commonStyle.scss';
import DataGrid from '../../../../../../Core/Controls/Grid';
import {
    getJobItemById,
    getJobItemsDropDown,
    getPartListByPartNumber,
    PostInternalVehicleJobsItem
} from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import SearchIcon from '@material-ui/icons/Search';
import { CustomIconButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import PastJobs from './../../JOBItems/getPastJobBy';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import _ from 'lodash';
const base_columns = [
    {
        key: 'vehicleJobPartNumber',
        name: 'Part Number',
        width: 120
    },
    {
        key: 'vehicleJobPartDescription',
        name: 'Part Description',
        minWidth: 150
    },
    {
        key: 'vehicleJobPartQuantity',
        name: 'Quantity',
        width: 70,
        formatter: formatters.ColumnTextAlignRight,
        headerRenderer: formatters.ColumnHeaderAlignRight
    },
    {
        key: 'vehicleJobPartUnitPrice',
        name: 'Unit Price',
        width: 100,
        formatter: formatters.NumberWithPound,
        headerRenderer: formatters.ColumnHeaderAlignRight
    },
    {
        key: 'vehicleJobPartCustomerUnitPrice',
        name: 'Customer Unit Price',
        width: 160,
        formatter: formatters.NumberWithPound,
        headerRenderer: formatters.ColumnHeaderAlignRight
    },
    {
        key: 'vehicleJobPartDiscount',
        name: 'Discount',
        width: 80,
        formatter: (data) => <div style={{ textAlign: 'right' }}>{data.row.vehicleJobPartDiscount || 0 + '%'}</div>,
        headerRenderer: formatters.ColumnHeaderAlignRight
    },
    {
        key: 'getCustTotal',
        name: 'Customer Total',
        headerRenderer: formatters.ColumnHeaderAlignRight,
        width: 130
    },
    {
        key: 'getTotal',
        name: 'Total',
        headerRenderer: formatters.ColumnHeaderAlignRight,
        width: 80
    }
];
const getTwoFixed = (val) => {
    return val ? val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 18,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 14
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const MatchingPartList = ({ list, loading, handleRowSelect }) => {
    return (
        <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto', width: '550px' }}>
            <TableContainer>
                <Table size="small" width="550px">
                    <TableHead>
                        <TableRow className="header">
                            <StyledTableCell>Number</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Quantity</StyledTableCell>
                            <StyledTableCell>Price</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((j, i) => (
                            <StyledTableRow className={`row${j.isSelected ? ' is-selected-j' : ''}`} onClick={() => handleRowSelect(j)}>
                                <StyledTableCell>{j.partNumber}</StyledTableCell>
                                <StyledTableCell>{j.description}</StyledTableCell>
                                <StyledTableCell>{j.quantity || 0}</StyledTableCell>
                                <StyledTableCell>{j.price || 0}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {list?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="4">{loading ? 'Loading...' : 'No record found.'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

function AddJobItemModal(props) {
    const [state, setState] = useState({
        setBackup: [],
        jobItemID: null,
        labourDescription: '',
        jobItemStatusID: null,
        JobItemCostTypeID: null,
        jobItemStatus: [],
        labourHours: '',
        labourRate: '',
        labourDiscount: 0,
        workCategory: '',
        costCategory: '',
        partNumber: '',
        partQuantity: null,
        partUnitPrice: null,
        vehicleJobPartCustomerUnitPrice: null,
        partDescription: '',
        partsDiscount: '',
        partsTotal: '',
        workList: [],
        costCategories: [],
        rows: [],
        errors: {},
        readOnly: false,
        showPastJob: false,
        costCategoryValue: null,
        jobItemStatusIDValue: null,
        jobItemCostTypeIDValue: null,
        workCategoryValue: null,
        showCancelConfirmModal: false,
        jobItemCustomerLabourTotal: 0,
        jobItemCustomerPartsPrice: 0,
        loading: false,
        focus: false
    });
    const { userRoles } = useLoginInfo();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);

    let checkhaveAccess = AppStorage.getAllowAcces();

    const [btnLoader, setBtnLoader] = useState(false);
    const handleRowSelect = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                id: null,
                partNumber: params.partNumber,
                partQuantity: params.quantity,
                partsDiscount: params?.discount,
                partUnitPrice: params.price,
                // vehicleJobPartCustomerUnitPrice: params.vehicleJobPartCustomerUnitPrice,
                // jobItemCustomerPartsPrice: getTwoFixed(
                //     (params.vehicleJobPartCustomerUnitPrice * params.vehicleJobPartCustomerQuantity * (100 - params.vehicleJobPartCustomerDiscount || 0)) / 100
                // ),
                partDescription: params.description,
                partsTotal: getTwoFixed((params.price * params.quantity * (100 - params?.discount || 0)) / 100),
                partId: null,
                loading: false,
                matchingPartNumber: [],
                focus: true
            };
            return nst;
        });

        setAnchorEl(null);
        setAnchorE2(null);
    };

    console.log(state.rows, 'state.rows');
    const handleUpdatePart = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                id: params.id,
                partNumber: params.vehicleJobPartNumber,
                partQuantity: params.vehicleJobPartQuantity,
                partsDiscount: params.vehicleJobPartDiscount,
                partUnitPrice: params.vehicleJobPartUnitPrice,
                vehicleJobPartCustomerUnitPrice: params.vehicleJobPartCustomerUnitPrice,
                jobItemCustomerPartsPrice: getTwoFixed(
                    (params.vehicleJobPartCustomerUnitPrice * params.vehicleJobPartCustomerQuantity * (100 - params.vehicleJobPartCustomerDiscount || 0)) / 100
                ),
                partDescription: params.vehicleJobPartDescription,
                partsTotal: params.partsTotal,
                partId: params.id,
                isUpdate: true
            };
            return nst;
        });
    };

    const handleDeletePart = (params) => {
        const id = params.id;
        const newList = state.rows.filter((item) => item.id !== id);
        let newPartToSetIsDelete = {
            id: params.id,
            partId: params.id,
            vehicleJobPartNumber: params.vehicleJobPartNumber,
            vehicleJobPartQuantity: params.vehicleJobPartQuantity,
            vehicleJobPartDiscount: params.vehicleJobPartDiscount,
            vehicleJobPartUnitPrice: params.vehicleJobPartUnitPrice,
            vehicleJobPartCustomerUnitPrice: params.vehicleJobPartCustomerUnitPrice,
            vehicleJobPartDescription: params.vehicleJobPartDescription,
            partsTotal: params.partsTotal,
            isDeleted: true
        };
        newList.push(newPartToSetIsDelete);
        setState((st) => {
            const nst = {
                ...st,
                rows: newList
            };
            return nst;
        });
    };
    let checkStatus = ['AA', 'JP', 'JQ', 'EA', 'EU'].includes(props.Status) && checkhaveAccess?.CanUpdateJobs;

    const ActionButtons = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <EditButton toolTipTitle="Edit Job Item" isDisabled={state.readOnly} onClick={() => handleUpdatePart(data.row)} />
                {checkhaveAccess?.CanDeleteJobLines && <DeleteButton onClick={() => handleDeletePart(data.row)} toolTipTitle="Delete Job Item" />}
            </div>
        );
    };

    const CalculateTotal = (data) => {
        const { vehicleJobPartQuantity, vehicleJobPartUnitPrice, vehicleJobPartDiscount } = data.row;
        const total = (vehicleJobPartUnitPrice * vehicleJobPartQuantity * (100 - vehicleJobPartDiscount || 0)) / 100;
        return <div style={{ textAlign: 'right' }}>£{getTwoFixed(total)}</div>;
    };
    const CalculateCutomerTotal = (data) => {
        const { vehicleJobPartCustomerUnitPrice, vehicleJobPartQuantity, vehicleJobPartDiscount } = data.row;
        let customerTotal = (vehicleJobPartCustomerUnitPrice * vehicleJobPartQuantity * (100 - vehicleJobPartDiscount || 0)) / 100;
        return <div style={{ textAlign: 'right' }}>£{getTwoFixed(customerTotal)}</div>;
    };
    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol2 = newColumn.find((item) => item.key === 'getTotal');
        let custTotalCol = newColumn.find((item) => item.key === 'getCustTotal');
        if (checkStatus) {
            newColumn.splice(0, 0, { key: 'action', name: 'Action', formatter: ActionButtons, width: '5%' });
        }
        if (actionCol2) {
            actionCol2.formatter = CalculateTotal;
        }
        if (custTotalCol) {
            custTotalCol.formatter = CalculateCutomerTotal;
        }
        return newColumn;
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        setState((st) => ({ ...st, islabourRateSet: props.islabourRateSet, labourRate: props.labourRate, markup: props.markup }));
        let res1 = await getJobItemsDropDown();
        if (res1.success) {
            const category = res1.data.vmrsCategory.map((item) => ({
                id: item.catid + ',' + item.subcatid,
                name: item.subcatname
            }));
            setState((st) => ({
                ...st,
                workList: category,
                costCategories: res1.data.costCategories,
                jobItemStatus: res1.data.jobItemStatus,
                costTypeList: res1.data.costType
            }));
        }
        if (props.jobItemID) {
            let partList = [];
            let res = await getJobItemById(props.jobItemID);
            partList = res.data.vehicleJobPartsDetails.map((item, index) => ({
                ...item,
                id: index + 1,
                isDeleted: false
            }));
            const info = res.data.vehicleJobitemdetails[0];
            if (res.success) {
                let newSt = {};
                newSt.jobItemID = info.jobItemID;
                newSt.labourDescription = info.jobItemDescription;
                newSt.jobItemJobID = info.jobItemJobID;
                newSt.jobItemStatusID = info.jobItemStatusID;
                newSt.jobItemCostTypeID = info.jobItemCostTypeID;
                newSt.workCategory = info.jobItemVMRSCategoryID + ',' + info.jobItemVMRSSubCategoryID;
                newSt.jobItemVMRSCategoryID = info.jobItemVMRSCategoryID;
                newSt.jobItemVMRSSubCategoryID = info.jobItemVMRSSubCategoryID;
                newSt.costCategory = info.jobItemCostCategoryID;
                newSt.rows = partList;
                newSt.readOnly = info.allowUpdate == 'Y' ? false : true;
                newSt.allowUpdate = info.allowUpdat;
                newSt.labourHours = info.jobItemLabourHours;
                newSt.labourRate = info.jobItemLabHourRate;
                newSt.jobItemCustomerLabHourRate = info.jobItemCustomerLabHourRate;
                newSt.jobItemCustomerLabourTotal = getTwoFixed(info.jobItemCustomerLabourCost);
                newSt.labourDiscount = info.jobItemLabourDiscount;
                newSt.jobItemCostTypeID = info.jobItemCostTypeID;
                setState((state) => ({
                    ...state,
                    ...newSt
                }));
            }
        }
    }, []);

    useEffect(() => {
        let newSt = {};
        if (state.workCategory && state.workList.length > 0) {
            newSt.workCategoryValue = state.workList?.find(({ id }) => id === state.workCategory);
        }
        if (state.costCategory && state.costCategories.length > 0) {
            newSt.costCategoryValue = state.costCategories?.find(({ id }) => id === state.costCategory);
        }

        if (state.jobItemStatusID && state.jobItemStatus.length > 0) {
            newSt.jobItemStatusIDValue = state.jobItemStatus?.find(({ id }) => id === state.jobItemStatusID);
        }
        if (state.jobItemCostTypeID && state.costTypeList.length > 0) {
            newSt.jobItemCostTypeIDValue = state.costTypeList?.find(({ id }) => id === state.jobItemCostTypeID);
        }
        setState((st) => ({
            ...st,
            ...newSt
        }));
    }, [
        state.workCategory,
        state.workList,
        state.costCategory,
        state.costCategories,
        state.jobItemStatusID,
        state.jobItemStatus,
        state.costTypeList,
        state.jobItemCostTypeID
    ]);

    const caltotal = (state.partUnitPrice * state.partQuantity * (100 - state.partsDiscount || 0)) / 100;
    const total = getTwoFixed(caltotal);

    const calLabourTotal = (state.labourRate * state.labourHours * (100 - state.labourDiscount || 0)) / 100;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let newSt = {};
        newSt[name] = value;
        newSt.setBackup = name;
        if (name === 'labourRate') {
            let val = value * (1 + state.markup / 100);
            newSt.jobItemCustomerLabHourRate = getTwoFixed(val);
            const custLabourTotal = (val * state.labourHours * (100 - state.labourDiscount || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'partUnitPrice') {
            let customerUnitPrice = value * (1 + state.markup / 100);
            newSt.vehicleJobPartCustomerUnitPrice = getTwoFixed(customerUnitPrice);
            const calCustTotal = (customerUnitPrice * state.partQuantity * (100 - state.partsDiscount || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        if (name === 'vehicleJobPartCustomerUnitPrice') {
            const calCustTotal = (value * state.partQuantity * (100 - state.partsDiscount || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        if (name === 'jobItemCustomerLabHourRate') {
            const custLabourTotal = (value * state.labourHours * (100 - state.labourDiscount || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'labourDiscount') {
            const custLabourTotal = (state.jobItemCustomerLabHourRate * state.labourHours * (100 - value || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'labourHours') {
            const custLabourTotal = (state.jobItemCustomerLabHourRate * value * (100 - state.labourDiscount || 0)) / 100;
            newSt.jobItemCustomerLabourTotal = getTwoFixed(custLabourTotal);
        }
        if (name === 'partsDiscount') {
            const calCustTotal = (state.vehicleJobPartCustomerUnitPrice * state.partQuantity * (100 - value || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        if (name === 'partQuantity') {
            const calCustTotal = (state.vehicleJobPartCustomerUnitPrice * value * (100 - state.partsDiscount || 0)) / 100;
            let customerPartsPriceTotal = getTwoFixed(calCustTotal);
            newSt.jobItemCustomerPartsPrice = customerPartsPriceTotal;
        }
        setState((state) => ({
            ...state,
            ...newSt
        }));
    };

    const WorkCategoryChange = (nm) => (e, val) => {
        if (nm === 'workCategoryValue') {
            const cat = val?.id?.split(',');
            if (cat?.length) {
                setState((state) => ({
                    ...state,
                    jobItemVMRSCategoryID: cat[0],
                    jobItemVMRSSubCategoryID: cat[1],
                    [nm]: val
                }));
            }
        } else {
            setState((state) => ({
                ...state,
                [nm]: val
            }));
        }
    };

    const validations = () => {
        const { labourDescription, workCategoryValue, labourRate, jobItemStatusID, labourHours, jobItemCostTypeIDValue } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(labourDescription)) {
            errors.labourDescription = 'Description is required';
            formIsValid = false;
        }
        if (!workCategoryValue) {
            errors.workCategory = 'Work Category is required';
            formIsValid = false;
        }
        if (!state.costCategoryValue) {
            errors.costCategory = 'Cost Category is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(labourRate)) {
            errors.labourRate = 'Rate is required';
            formIsValid = false;
        }
        if (!state.jobItemStatusIDValue) {
            errors.jobItemStatusID = 'Status is required';
            formIsValid = false;
        }
        if (!jobItemCostTypeIDValue) {
            errors.jobItemCostTypeIDValue = 'Cost Type is required';
            formIsValid = false;
        }

        if (!labourHours) {
            errors.labourHours = 'Hours is required';
            formIsValid = false;
        }

        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitSendForApproval = async (val) => {
        if (validations()) {
            setBtnLoader(true);
            const PartsVM = state.rows
                .filter((data) => data.isDeleted != true)
                .map((row) => ({
                    vehicleJobPartNumber: row.vehicleJobPartNumber,
                    vehicleJobPartQuantity: row.vehicleJobPartQuantity,
                    vehicleJobPartDiscount: row.vehicleJobPartDiscount,
                    vehicleJobPartUnitPrice: row.vehicleJobPartUnitPrice,
                    vehicleJobCustomerUnitPrice: row.vehicleJobPartCustomerUnitPrice,
                    vehicleJobPartDescription: row.vehicleJobPartDescription
                }));
            const data = {
                jobItemDescription: state.labourDescription,
                jobItemLabourHours: state.labourHours,
                jobItemLabHourRate: state.labourRate,
                jobItemCustomerLabHourRate: state.jobItemCustomerLabHourRate,
                jobItemLabourDiscount: state.labourDiscount || 0,
                jobItemID: state.jobItemID,
                jobItemVMRSCategoryID: state.jobItemVMRSCategoryID,
                jobItemVMRSSubCategoryID: state.jobItemVMRSSubCategoryID,
                jobItemJobID: props.jobItemJobID,
                jobItemCostCategoryID: state.costCategoryValue?.id,
                vehicleJobPartsVM: PartsVM,
                jobItemStatusID: state.jobItemStatusIDValue?.id,
                jobItemCostTypeID: state.jobItemCostTypeIDValue?.id,
                sendForApproval: 0
            };
            let res = await PostInternalVehicleJobsItem(data);
            if (res.success) {
                showToastSuccess('Job item saved successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    const addPartToLocally = () => {
        if (state.partDescription === '') {
            showToastError('Description is required');
        }
        if (state.partNumber === '') {
            showToastError('Quantity is required');
        }
        if (state.partQuantity === null || state.partQuantity === '') {
            showToastError('Quantity is required');
        }
        if (state.partUnitPrice === null || state.partUnitPrice === '') {
            showToastError('Price is required');
        } else {
            let newPartToAdd = {
                vehicleJobPartNumber: state.partNumber,
                vehicleJobPartQuantity: state.partQuantity,
                vehicleJobPartDiscount: state.partsDiscount,
                vehicleJobPartUnitPrice: state.partUnitPrice,
                vehicleJobPartCustomerUnitPrice: state.vehicleJobPartCustomerUnitPrice,
                vehicleJobPartDescription: state.partDescription,
                isDeleted: false,
                partsTotal: getTwoFixed((state.partUnitPrice * state.partQuantity * (100 - state.partsDiscount || 0)) / 100)
            };
            setState((st) => ({
                ...st,
                rows: state.rows.filter((item) => item.id !== state.partId)
            }));

            setState((st) => {
                newPartToAdd.id = st.rows.length === 0 ? 1 : Math.max(...st.rows.map((c) => c.id)) + 1;
                return {
                    ...st,
                    rows: [...st.rows, newPartToAdd],
                    partNumber: '',
                    partQuantity: '',
                    partsDiscount: '',
                    partUnitPrice: '',
                    vehicleJobPartCustomerUnitPrice: '',
                    partDescription: '',
                    partsTotal: '',
                    isUpdate: false,
                    partId: undefined,
                    jobItemCustomerPartsPrice: 0
                };
            });
        }
    };
    const closeTheDialog = () => {
        setState((st) => ({ ...st, showPastJob: false }));
    };

    const handleClose = () => {
        props.onClose(false);
    };

    const handleOpen = () => {
        setState((st) => ({ ...st, showCancelConfirmModal: !st.showCancelConfirmModal }));
    };

    let setbtn = state.setBackup.length > 0 ? handleOpen : handleClose;

    const pullDataByPartNumber = async (e, type) => {
        let res;
        console.log(type);
        if (type == 'P') {
            if (state.partNumber) {
                setAnchorEl(e.currentTarget);
                res = await getPartListByPartNumber(state.partNumber, null);
                if (res.success) {
                    setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, loading: false }));
                }
            } else {
                showToastError('Please enter part number to fetch data');
            }
        } else {
            if (state.partDescription) {
                setAnchorE2(e.currentTarget);
                res = await getPartListByPartNumber(null, state.partDescription);
                if (res.success) {
                    setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, loading: false }));
                }
            } else {
                showToastError('Please enter description to fetch data');
            }
        }
    };

    const handlePoperClose = () => {
        setAnchorEl(null);
        setAnchorE2(null);
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorE2);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open2 ? 'simple-popover2' : undefined;

    return (
        <div className="controller-screen mt_10">
            {state.showPastJob && (
                <DialogComp title={`Past Jobs: ${props.vehicleRegNumber} ${state.workCategory}`} maxWidth="lg" onClose={closeTheDialog} fullWidth>
                    <PastJobs
                        onClose={closeTheDialog}
                        jobId={props.jobItemJobID}
                        vehicleID={props.vehicleID}
                        VMRSCategoryID={state.jobItemVMRSCategoryID}
                        VMRSSubCategoryID={state.jobItemVMRSSubCategoryID}
                        vehicleRegNumber={props.vehicleRegNumber}
                    />
                </DialogComp>
            )}

            <Grid container direction="row" spacing={2}>
                {checkStatus && (
                    <Grid item xs={12} sm={2} lg={2}>
                        <SecondaryButton isDisabled={state.readOnly} isBtnLoader={btnLoader} onClick={submitSendForApproval}>
                            Save
                        </SecondaryButton>
                    </Grid>
                )}
                <Grid item xs={12} sm={2} lg={2}>
                    <SecondaryButton
                        onClick={
                            setbtn
                            //     () => {
                            //     props.onClose(false);
                            // }
                        }
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
                <Grid item xs={12} sm={6} md={6} xl={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Labour Description"
                        fullWidth
                        name="labourDescription"
                        placeholder="Description"
                        onChange={handleInputChange}
                        value={state.labourDescription}
                        errors={state.errors.labourDescription}
                        isDisabled={state.readOnly}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={1} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Hours"
                        isDisabled={state.readOnly}
                        placeholder="Hours"
                        value={state.labourHours}
                        onChange={handleInputChange}
                        errors={state.errors.labourHours}
                        name="labourHours"
                    />
                </Grid>
                <Grid container direction="row" spacing={1} item sm={12} md={8} xl={4}>
                    <Grid item xs={3} sm={4} md={4} lg={4} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Labour Rate"
                            name="labourRate"
                            placeholder="Rate"
                            onChange={handleInputChange}
                            value={state.labourRate}
                            errors={state.errors.labourRate}
                            isDisabled={state.readOnly}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={5} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Customer Labour Rate"
                            name="jobItemCustomerLabHourRate"
                            placeholder="Rate"
                            onChange={handleInputChange}
                            value={state.jobItemCustomerLabHourRate}
                            errors={state.errors.jobItemCustomerLabHourRate}
                            isDisabled={state.readOnly}
                        />
                    </Grid>
                    <Grid item xs={3} sm={4} md={4} lg={3}>
                        <SimpleField
                            inputLabel="Discount %"
                            name="labourDiscount"
                            isDisabled={state.readOnly}
                            placeholder="Discount"
                            onChange={handleInputChange}
                            value={state.labourDiscount}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} item md={4} xl={3}>
                    <Grid item xs={12} sm={6} md={6} lg={5} className="input-style_right">
                        <InputLabel shrink className="input_label">
                            Customer Total
                        </InputLabel>
                        <div style={{ textAlign: 'right' }} className="input_value">
                            £{state.jobItemCustomerLabourTotal || 0}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={7} className="input-style">
                        <div className="input-style">
                            <InputLabel shrink className="input_label">
                                Total
                            </InputLabel>
                            <div style={{ textAlign: 'right' }} className="input_value">
                                £{calLabourTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} md={4} xl={4} className="mandatory-fields">
                    <Grid item xs={11}>
                        <SingleSelect
                            inputLabel="Work Category"
                            isRequired={true}
                            isDisabled={state.readOnly}
                            style={{ marginTop: -8 }}
                            options={state.workList}
                            value={state.workCategoryValue}
                            onChange={WorkCategoryChange('workCategoryValue')}
                        />
                    </Grid>
                    {state.workCategory && (
                        <Grid item xs={1}>
                            <InputLabel>{'\u{2800}'}</InputLabel>
                            <CustomIconButton
                                iconFontSize={25}
                                toolTipTitle="Get past jobs for the vehicle against this work category"
                                onClick={() => {
                                    setState((st) => ({ ...st, showPastJob: true }));
                                }}
                                icon={SearchIcon}
                            />
                        </Grid>
                    )}
                    <FormHelperText error>{state.errors.workCategory}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <SingleSelect
                        inputLabel="Cost Category"
                        isDisabled={state.readOnly}
                        style={{ marginTop: -8 }}
                        options={state.costCategories}
                        value={state.costCategoryValue}
                        onChange={WorkCategoryChange('costCategoryValue')}
                    />
                    <FormHelperText error>{state.errors.costCategory}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="mandatory-fields">
                    <SingleSelect
                        inputLabel=" Cost Type"
                        isRequired={true}
                        style={{ marginTop: -8 }}
                        options={state.costTypeList}
                        value={state.jobItemCostTypeIDValue}
                        isDisabled={props.isCustomer || state.readOnly}
                        onChange={WorkCategoryChange('jobItemCostTypeIDValue')}
                    />
                    <FormHelperText error>{state.errors.jobItemCostTypeIDValue}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={2} className="mandatory-fields">
                    <SingleSelect
                        inputLabel="Status"
                        isRequired={true}
                        style={{ marginTop: -8 }}
                        options={state.jobItemStatus}
                        value={state.jobItemStatusIDValue}
                        onChange={WorkCategoryChange('jobItemStatusIDValue')}
                        isDisabled={props.isCustomer || state.readOnly}
                    />
                    <FormHelperText error>{state.errors.jobItemStatusID}</FormHelperText>
                </Grid>
            </Grid>
            {checkStatus && (
                <>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} sm={3} md={2} lg={2} className="mandatory-fields">
                            <MandatoryField
                                isDisabled={state.readOnly}
                                inputLabel="Part Number"
                                name="partNumber"
                                placeholder="Part Number"
                                onChange={handleInputChange}
                                value={state.partNumber}
                                errors={state.errors.partNumber}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={'Fetch Part Details By Number'} arrow>
                                                <Grid>
                                                    <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'P')} size="small">
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Popover
                            open={open}
                            id="simple-popover"
                            anchorEl={anchorEl}
                            onClose={handlePoperClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            style={{ left: '-240px' }}
                        >
                            <MatchingPartList list={state.matchingPartNumber || []} loading={state.loading} handleRowSelect={handleRowSelect} />
                        </Popover>
                        <Grid item xs={12} sm={3} md={3} lg={2} className="mandatory-fields">
                            <MandatoryField
                                isDisabled={state.readOnly}
                                inputLabel="Part Description"
                                name="partDescription"
                                placeholder="Part Description"
                                onChange={handleInputChange}
                                value={state.partDescription}
                                errors={state.errors.partDescription}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={'Fetch Part Details By Description'} arrow>
                                                <Grid>
                                                    <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'D')} size="small">
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Popover
                                open={open2}
                                id="simple-popover2"
                                anchorEl={anchorE2}
                                onClose={handlePoperClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                style={{ left: '-240px' }}
                            >
                                <MatchingPartList list={state.matchingPartNumber || []} loading={state.loading} handleRowSelect={handleRowSelect} />
                            </Popover>
                        </Grid>
                        <Grid item xs={6} sm={6} md={1} lg={1} className="mandatory-fields">
                            <MandatoryField
                                isDisabled={state.readOnly}
                                inputLabel="Quantity"
                                name="partQuantity"
                                placeholder="Quantity"
                                onChange={handleInputChange}
                                value={state.partQuantity}
                                autoFocus={state.focus}
                            />
                        </Grid>
                        <Grid container direction="row" spacing={1} item sm={12} md={5} lg={4}>
                            <Grid item xs={6} sm={4} md={4} lg={4} className="mandatory-fields">
                                <MandatoryField
                                    isDisabled={state.readOnly}
                                    inputLabel="Unit Price"
                                    name="partUnitPrice"
                                    type="number"
                                    placeholder="Price"
                                    onChange={handleInputChange}
                                    value={state.partUnitPrice}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} lg={5} className="mandatory-fields">
                                <MandatoryField
                                    isDisabled={state.readOnly}
                                    inputLabel="Customer Unit Price"
                                    name="vehicleJobPartCustomerUnitPrice"
                                    type="number"
                                    placeholder="Price"
                                    onChange={handleInputChange}
                                    value={state.vehicleJobPartCustomerUnitPrice}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} lg={3}>
                                <SimpleField
                                    isDisabled={state.readOnly}
                                    inputLabel="Discount %"
                                    name="partsDiscount"
                                    type="number"
                                    placeholder="Discount"
                                    onChange={handleInputChange}
                                    value={state.partsDiscount}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={2} md={1}>
                            <div className="input-style">
                                <InputLabel shrink className="input_label">
                                    Customer Total
                                </InputLabel>
                                <div style={{ textAlign: 'right' }} className="input_value">
                                    £{state.jobItemCustomerPartsPrice || 0}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={2} md={1}>
                            <div className="input-style">
                                <InputLabel shrink className="input_label">
                                    Total
                                </InputLabel>
                                <div className="input_value">£{total}</div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4} md={1} className="submit_btn" style={{ marginTop: 18 }}>
                            <SecondaryButton isDisabled={state.readOnly} onClick={addPartToLocally}>
                                {state.partId ? 'Update' : 'Add'}
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </>
            )}
            {state.showCancelConfirmModal ? (
                <DialogComp title={''} onClose={handleOpen} maxWidth="lg">
                    <ConfirmationModal message={'Are you sure do you want to close job Item Screen'} handleCancel={handleOpen} handleSubmit={props.onClose} />
                </DialogComp>
            ) : null}
            <Grid item xs={12}>
                <div className="controller-screen">
                    <DataGrid
                        columns={columns}
                        offset={530}
                        rows={state.rows.filter((item) => item.isDeleted !== true)}
                        isRowHovered={true}
                        loadingData={state.loader}
                    />
                </div>
            </Grid>
        </div>
    );
}

export default AddJobItemModal;
