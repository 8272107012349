import * as Components from '../../../../Components';
import { TypeOfAuth } from '../menu-auth-type';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BuildIcon from '@material-ui/icons/Build';
import GavelIcon from '@material-ui/icons/Gavel';
import HealingIcon from '@material-ui/icons/Healing';
import WorkIcon from '@material-ui/icons/Work';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';

export default [
    {
        label: 'Dashboard',
        path: '/',
        exact: true,
        component: Components.ServiceAgentDash,
        authType: TypeOfAuth.Both,
        icon: <DashboardIcon />
    },
    //   {
    //     label: "Defect",
    //     path: "/defect",
    //     exact: true,
    //     component: Components.ServiceAgentDashboard,
    //     authType: TypeOfAuth.Both,
    //     icon: <GavelIcon />,
    //   },

    // {
    //     label: 'Schedules',
    //     path: '/allvehicleschedules',
    //     exact: true,
    //     component: Components.AllVehicleSchedules,
    //     authType: TypeOfAuth.Both,
    //     icon: <BuildIcon />
    // },
    {
        label: 'BookJob',
        path: '/controller/jobTabScreen/:id/:vehicleID/:tabIndex?',
        exact: true,
        component: Components.jobTabScreen,
        hidden: true,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Schedules',
        authType: TypeOfAuth.Both,
        icon: <ScheduleIcon />,
        children: []
    },
    {
        label: 'Booking Requests',
        path: '/schedule/bookingrequests',
        exact: true,
        component: Components.BookingRequests,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Confirmed Bookings',
        path: '/schedule/confirmedbookings',
        exact: true,
        component: Components.ConfirmedBookings,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Booking Accepted',
        path: '/schedule/bookingaccepted',
        exact: true,
        component: Components.AcceptRejectByAgent,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Booking Rejected',
        path: '/schedule/bookingrejected',
        exact: true,
        component: Components.AcceptRejectByAgent,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Defects',
        path: '/serviceagent/defect',
        exact: true,
        component: Components.ServiceAgentDefectsScreen,
        authType: TypeOfAuth.Both,
        icon: <GavelIcon />
    },
    {
        label: 'Defects',
        path: '/serviceagent/defects',
        exact: true,
        component: Components.DefetctStatusGrid,
        authType: TypeOfAuth.Auth,
        hidden: true
    },
    {
        label: 'Breakdowns',
        authType: TypeOfAuth.Auth,
        icon: <HealingIcon />,
        children: []
    },
    {
        label: 'All Breakdowns',
        path: '/maintenance/breakdowns/:all',
        exact: true,
        component: Components.ServiceAgentBreakdown,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Breakdown Onsite',
        path: '/maintenance/breakdowns/typebo',
        exact: true,
        component: Components.BreakDownDashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Breakdown Roadsite',
        path: '/maintenance/breakdowns/typebr',
        exact: true,
        component: Components.BreakDownDashboard,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimates',
        authType: TypeOfAuth.Both,
        icon: <ExploreRoundedIcon />,
        children: []
    },
    {
        label: 'Awaiting Approval',
        path: '/serviceagent/EP',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="EP" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimates Awaiting Approvals',
        path: '/serviceagent/EC',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="EC" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimate Revised',
        path: '/serviceagent/ER',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="ER" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimate Queried',
        path: '/serviceagent/EQ',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="EQ" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Estimate Accepted',
        path: '/serviceagent/EA',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="EA" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Jobs',
        authType: TypeOfAuth.Both,
        icon: <WorkIcon />,
        children: []
    },
    {
        label: 'Awaiting Approvals',
        path: '/serviceagent/AR',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="AR" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Approved',
        path: '/serviceagent/CA',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="CA" />;
        },
        authType: TypeOfAuth.Auth
    },
    // {
    //     label: 'Driver Defects',
    //     path: '/serviceagent/DD',
    //     exact: true,
    //     component: ()=>{return <Components.CommonJobsScreen screenCode="DD"/>},
    //     authType: TypeOfAuth.Auth
    // },
    {
        label: 'Open Jobs',
        path: '/serviceagent/OJ',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="JP" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Queried Jobs',
        path: '/serviceagent/QJ',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="JQ" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Job Completed',
        path: '/serviceagent/JC',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="JC" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditBreakDown',
        path: '/maintenance/breakdowns/Edit/:BreakDownID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.EditBreakdownLink,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'EditDefect',
        path: '/maintenance/defect/edit/:DefectID/:VehicleID',
        exact: true,
        hidden: true,
        component: Components.DefectEditDetails,
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Inspections',
        path: '/inspection',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={1} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Repair Required',
        path: '/inspection_repair_required',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={1} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Awaiting Sign Off',
        path: '/inspection_completed',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={3} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Repair In Progress',
        path: '/in_progress',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={2} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Signed Off',
        path: '/signed_off',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={4} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Awaiting Cost Review',
        path: '/serviceagent/FR',
        exact: true,
        component: () => {
            return <Components.CommonJobsScreen screenCode="FR" />;
        },
        authType: TypeOfAuth.Auth
    }
    // {
    //     label: 'Schedule Defects',
    //     path: '/serviceagent/SD',
    //     exact: true,
    //     component: ()=>{return <Components.CommonJobsScreen screenCode="SD"/>},
    //     authType: TypeOfAuth.Auth
    // },
];
