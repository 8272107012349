import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Backdrop, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { formatters, DataGrid } from '../../../../Core/Controls';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { DefectsProvider as StateProvider, DefectsScreenContext } from './DefectsProvider';
import DefectDetails from './defectDetail';
import CustomizedSteppers from '../BreackdownDefectStepper/defectBreackDownStepper';
import '../../Controllers/controllerStyle.scss';
import AddEditNotes from '../../Controllers/Notes/progressReminder'; //'./addEditNotes';
import { CustomChip } from '../../../../Core/Controls';
import AddVor from '../../Controllers/VOR/AddEditVor';
import FileUploaderButton from '../../../../Core/Controls/GenericFileUploader';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { FromLoader } from '../../../../Core/Loader/formLoader';
import moment from 'moment';
import AddEditNewNotes from '../../Controllers/Notes/jobNotes'; //'./addEditNotes';
import DeleteJobModal from '../../Controllers/JOBS/deleteJobModal';
import JobStepperScreen from '../BreackdownDefectStepper/addJobStepper';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import AddDefectBreakEditJobsNotes from '../../Controllers/Notes/defectBreakdownNotes';

const JobSingleNote = (props) => {
    const [state, setState] = useState({ show: false });
    const { note, createdBy, dateCreated, dateClosed, closedBy, noteID, rd } = props.note;
    let checkIsClosed = dateClosed || closedBy;
    const closeModal = (res) => {
        setState({ show: false });
        res && props.onFormSubmit(true);
    };
    const showModal = () => {
        if (!checkIsClosed) {
            setState({ show: true });
        }
    };
    return (
        <>
            {state.show ? (
                <AddEditNewNotes
                    DisplayData={props.DisplayData}
                    noteRefsGuidID={props.noteRefsGuidID}
                    defectID={props.defectID}
                    NoteID={noteID}
                    frompage="Defects"
                    notesTitle="BreakDown"
                    driverName={props.driverName}
                    onClose={() => closeModal(false)}
                    reportedBy={props.reportedBy}
                    titleModal="Progress Update Notes"
                    reportedPhone={props.reportedPhone}
                    onFormSubmit={() => closeModal(true)}
                />
            ) : null}
            <div className="single-note">
                <div
                    className="msg"
                    style={{ fontSize: 14, fontStyle: 'italic', ...(!checkIsClosed && { color: '#f50057', cursor: 'pointer' }) }}
                    onClick={showModal}
                >
                    {note}
                </div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'left', float: 'left' }}>
                    {moment(rd).format('LLL')}
                </div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right', float: 'right' }}>
                    {createdBy} @ {moment(dateCreated).format('LLL')}
                    {(dateClosed || closedBy) && ` (Closed by: ${closedBy} @ ${moment(dateClosed).format('LLL')} )`}
                </div>
                <br />
                <hr style={{ opacity: '30%' }} />
            </div>
        </>
    );
};

const DefectEditDetails = (props) => {
    const { defectFields, userSettings, submitHandler, DefectID, pullDefectByID, pullNotes, setDefectFields } = useContext(DefectsScreenContext);
    const [state, setState] = useState({});
    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');
    const isCustomer = userRoles.includes('customer');
    const isInternal = userRoles.includes('internal');
    const isServiceAgent = userRoles.includes('serviceagent');
    const history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const closeNoteModal = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showDefectBreakNotes: false
        }));
        res && pullNotes();
    };

    const closeVORModal = (res) => {
        setState((st) => ({
            ...st,
            showVORPopup: false,
            showJobStepperScreen: false
        }));
        // res && props.pullDetails();
        res && pullDefectByID();
    };

    const closeDeleteDefectModal = (res) => {
        setState((st) => ({
            ...st,
            showJobDeleteModal: false
        }));
        if (res) {
            history.push({
                pathname: `/maintenance/defects`,
                search: '',
                state: ''
            });
        }
    };

    const addNotes = (show) => {
        setState((st) => ({
            ...st,
            [show]: true
        }));
    };

    const showModalHandler = (screenName) => {
        setState((st) => ({
            ...st,
            [screenName]: true
        }));
    };
    function isValYesNo(val) {
        return val ? 'Yes' : 'No';
    }

    const handleColse = () => {
        setDefectFields((st) => ({ ...st, defectNumberGenerated: false }));
        props.onClose();
    };

    const handleDefetcBreakDownReporting = (val) => {
        history.push({
            pathname: `/maintenance/defect/reporting/${defectFields.customerID}`,
            state: 'Edit'
        });
    };

    let currentNoteID = defectFields?.NoteList ? defectFields?.NoteList[0]?.noteID : null;
    let newNoteID = defectFields?.NoteList?.find((o) => o.closedBy !== null)?.noteID;
    return (
        <div className="controller-screen" style={{ marginTop: -10, padding: 20 }}>
            {defectFields.isReload ? (
                <FromLoader />
            ) : (
                <>
                    {defectFields.defectNumberGenerated ? (
                        <DefectDetails data={defectFields} isAddJobBtnShow={props.isAddJobBtnShow} pullDefectByID={pullDefectByID} onClose={handleColse} />
                    ) : null}
                    <Grid container spacing={1}>
                        {defectFields.vehicleJobProgress.map((a, index) => (
                            <Grid item xs={12} key={index}>
                                <CustomizedSteppers data={a} userData={defectFields} onStepperSubmit={pullDefectByID} fromPage={false} />
                            </Grid>
                        ))}
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12} md={12} lg={false ? 12 : 6}>
                                {/* <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style grey-grid">
                            <Grid item style={{ padding: '5px 10px' }}>
                                <Typography variant="h5" color="secondary" style={{ width: '100%' }}>
                                    Defect Details
                                </Typography>
                            </Grid>
                            <Grid container item>
                                <Grid>
                                    <span style={{ float: 'right', marginBottom: 5 }}>
                                        <FileUploaderButton
                                            recordId={DefectID}
                                            fileUploaderConfigName="DefectScreen"
                                            // fileCount={props.fileCount ? props.fileCount : 0}
                                        />
                                    </span>
                                </Grid>
                                {!defectFields.vorVehicleID && (
                                    <Grid item style={{ paddingTop: 1, paddingBottom: 3, paddingRight: 3 }}>
                                        <CustomChip icon={AddIcon} label="Add VOR" onClick={showVorModal} style={{ fontWeight: 'bold' }} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid> */}
                                <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style grey-grid">
                                    <Grid item>
                                        <Typography variant="h5" color="secondary">
                                            Defect Details
                                            <span style={{ position: 'relative', top: '-7px', left: '7px' }}>
                                                <FileUploaderButton
                                                    recordId={DefectID}
                                                    fileUploaderConfigName="DefectScreen"
                                                    fileCount={defectFields.fileCount}
                                                />
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ paddingTop: 1, paddingBottom: 3, paddingRight: 3 }}>
                                        {checkhaveAccess?.CanAddVOR && !defectFields.vorVehicleID && !isDriver && !isCustomer && (
                                            <CustomChip
                                                icon={AddIcon}
                                                label="Add VOR"
                                                onClick={() => showModalHandler('showVORPopup')}
                                                style={{ fontWeight: 'bold', marginRight: 10 }}
                                            />
                                        )}
                                        {!isDriver && !isCustomer && (
                                            <>
                                                {checkhaveAccess?.CanUpdateJobs && defectFields.defectNumberGenerated && !props.isAddJobBtnShow ? (
                                                    <CustomChip
                                                        icon={AddIcon}
                                                        label="Add Repair"
                                                        onClick={() => showModalHandler('showJobStepperScreen')}
                                                        style={{ fontWeight: 'bold', marginRight: 10 }}
                                                    />
                                                ) : null}
                                                {checkhaveAccess?.CanUpdateVehicleDefects && (
                                                    <CustomChip
                                                        icon={DeleteIcon}
                                                        label="Delete Defect"
                                                        onClick={() => showModalHandler('showJobDeleteModal')}
                                                        style={{ fontWeight: 'bold' }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={12} style={{ overflowY: 'auto', padding: 7 }} className="grid-style white-grid">
                                        {/* <FormHelperText shrink>Defect Details</FormHelperText> */}
                                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                                            {defectFields.defectDescription?.toUpperCase() || 'N\\A'}
                                        </Typography>
                                    </Grid>
                                    <Grid container item className="grid-style grey-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Reporting Depot</FormHelperText>
                                            <Typography variant="button">{defectFields.reportingDepot || 'N\\A'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Customer Defect Number</FormHelperText>
                                            <Typography variant="button">{defectFields.defectCustomerDefectNumber || 'N\\A'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Vehicle Location</FormHelperText>
                                            <Typography variant="button">{defectFields.defectLocation || 'N\\A'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style white-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Mileage</FormHelperText>
                                            <Typography variant="button">{defectFields.defectMileage || 'N\\A'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Reported By</FormHelperText>
                                            <Typography variant="button">{defectFields.defectContactName || 'N\\A'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Reported Phone</FormHelperText>
                                            <Typography variant="button">
                                                {formatters.phoneNumberFormatter(defectFields.defectContactNumber) || 'N\\A'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style grey-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Driver Name</FormHelperText>
                                            <Typography variant="button">{defectFields.defectDriverName || 'N\\A'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Driver Phone</FormHelperText>
                                            <Typography variant="button">
                                                {formatters.phoneNumberFormatter(defectFields.defectDriverTelephone) || 'N\\A'}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Authorised By</FormHelperText>
                                            <Typography variant="button">{defectFields.defectAuthorisedBy || 'N\\A'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style white-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Preferred Service Agent</FormHelperText>
                                            <Typography variant="button">{defectFields.defectPreferredServiceAgent || 'N\\A'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Already Booked?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectAlreadyBooked)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Already Completed?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectAlreadyCompleted)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style grey-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Call Out Required</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectCallOutRequired)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Urgent Call Out</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectUrgentCallOut)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Put On VOR</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectVOR)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style white-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Advised Don't Drive?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectAdvisedDontDrive)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Passenger Involved?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectPassengersInvolved)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Number Of Passenger</FormHelperText>
                                            <Typography variant="button">{defectFields.defectNumberOfPassengers || 'N\\A'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style grey-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Accident Damage?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectAccidentDamage)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Insurance Reference</FormHelperText>
                                            <Typography variant="button">{defectFields.defectInsuranceReference || 'N\\A'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Replacement vehicle Required?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectReplacementVehicle)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style white-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Vehicle Loaded?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectVehicleLoaded)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink> Load Type</FormHelperText>
                                            <Typography variant="button">{userSettings.vehicleLoadTypeDescription}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Police On Scene?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectPoliceOnScene)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item className="grid-style grey-grid">
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Mobility Requested?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectMobilityRequested)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>Mobility Used?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectMobilityUsed)}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormHelperText shrink>PG9 Issued?</FormHelperText>
                                            <Typography variant="button">{isValYesNo(userSettings.defectPG9Issued)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {true && (
                                <Grid item xs={12} md={12} lg={6}>
                                    <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style grey-grid">
                                        <Grid item>
                                            <Typography variant="h5" color="secondary">
                                                Job Progress
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ paddingTop: 1, paddingBottom: 3, paddingRight: 3 }}>
                                            {/* <CustomChip
                                                icon={EditIcon}
                                                iconFontSize={16}
                                                label="Defect Reporting"
                                                style={{ fontWeight: 'bold' }}
                                                onClick={handleDefetcBreakDownReporting}
                                            /> */}

                                            {isInternal && (
                                                <CustomChip
                                                    icon={AddIcon}
                                                    label="Add Progress Reminder"
                                                    onClick={() => addNotes('show')}
                                                    style={{ fontWeight: 'bold', marginRight: 10 }}
                                                />
                                            )}
                                            {checkhaveAccess?.CanViewNotes && (
                                                <CustomChip
                                                    icon={AddIcon}
                                                    label="Add Notes"
                                                    onClick={() => addNotes('showDefectBreakNotes')}
                                                    style={{ fontWeight: 'bold' }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                    <DataGrid columns={columns} rows={defectFields.NoteList} offset={435} />
                                </Grid> */}
                                    <Grid item xs={12} style={{ padding: '5px', minHeight: '471px' }} className="grid-style">
                                        {defectFields.NoteList?.map((n, i) => (
                                            <>
                                                <JobSingleNote
                                                    key={i}
                                                    note={n}
                                                    DisplayData={defectFields.driverPhone}
                                                    driverName={defectFields.driverName}
                                                    reportedBy={defectFields.reportedBy}
                                                    reportedPhone={defectFields.reportedPhone}
                                                    onFormSubmit={() => closeNoteModal(true)}
                                                    noteRefsGuidID={defectFields.defectID}
                                                    defectID={defectFields.defectID}
                                                />
                                            </>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
            {state.show ? (
                <AddEditNotes
                    DisplayData={defectFields.defectDriverTelephone}
                    noteRefsGuidID={DefectID}
                    defectID={DefectID}
                    frompage="Defects"
                    notesTitle="Defects"
                    driverName={defectFields.defectDriverName}
                    onClose={() => closeNoteModal(false)}
                    onFormSubmit={closeNoteModal}
                    reportedBy={defectFields.defectContactName}
                    reportedPhone={defectFields.defectContactNumber}
                />
            ) : null}
            {state.showDefectBreakNotes ? (
                <AddDefectBreakEditJobsNotes
                    DisplayData={defectFields.defectDriverTelephone}
                    noteRefsGuidID={DefectID}
                    defectID={DefectID}
                    frompage="Defects"
                    notesTitle="Defects"
                    driverName={defectFields.defectDriverName}
                    onClose={() => closeNoteModal(false)}
                    onFormSubmit={closeNoteModal}
                    reportedBy={defectFields.defectContactName}
                    reportedPhone={defectFields.defectContactNumber}
                />
            ) : null}
            {state.showVORPopup ? (
                <DialogComp title={`${defectFields.vorVehicleID ? 'Update' : 'Add'} VOR`} onClose={() => closeVORModal(false)} maxWidth="lg" overflow="auto">
                    <AddVor
                        VehicleID={defectFields.defectVehicleID}
                        update={defectFields.vorVehicleID}
                        vorVehicleID={defectFields.vorVehicleID}
                        onClose={closeVORModal}
                        defectID={DefectID}
                        fromPage="Defect"
                    />
                </DialogComp>
            ) : null}
            {state.showJobDeleteModal ? (
                <DialogComp open={true} onClose={() => closeDeleteDefectModal(false)} title={'Delete Job'} maxWidth="sm" fullWidth>
                    <DeleteJobModal deleteID={defectFields.defectID} fromPage="Defect" onClose={closeDeleteDefectModal} />
                </DialogComp>
            ) : null}
            {state.showJobStepperScreen ? (
                <DialogComp title="Add Repair" onClose={() => closeVORModal(false)} maxWidth="sm">
                    <JobStepperScreen onClose={closeVORModal} userData={defectFields} />
                </DialogComp>
            ) : null}
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <DefectEditDetails {...props} />
        </StateProvider>
    );
};
export default Screen;
