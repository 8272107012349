import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';
import { memoize } from 'lodash';

export const getAllJobItems = async (id) => {
    let res = await getWithAuth(`VehicleJobItems?VehicleJobID=${id}`);
    return res;
};

export const getJobItemById = async (id) => {
    let res = await getWithAuth(`VehicleJobItems/${id}`);
    return res;
};

export const getPartListByPartNumber = async (number, desc) => {
    let res = await getWithAuth(`VehicleJobItems/GetPartByPartNumber?${number ? `PartNumber=${number}` : ''}${desc ? `PartDescription=${desc}` : ''}`);
    return res;
};

export const getJobItemsDropDown = memoize(async () => {
    let res = await getWithAuth(`VehicleJobItems/Dropdowns`);
    return res;
});

export const postJobItem = async (data) => {
    let res = await postWithAuth(`VehicleJobItems`, data);
    return res;
};
export const createInvoice = async (data) => {
    let res = await postWithAuth(`Invoices/InvoiceAddFromApprovedItems`, data);
    return res;
};

export const salesInvoice = async (data) => {
    let res = await postWithAuth(`Invoices/SalesInvoiceAddFromApprovedItems`, data);
    return res;
};

export const getSalesDropdown = async (id) => {
    let res = await getWithAuth(`Invoices/SalesInvoiceScreenDropdowns?id=${id}`);
    return res;
};

export const getPastJobByVMRSCategoryNSubCategory = async (jobId, VehicleId, VMRSCatId, VMRSSubCatId) => {
    let res = await getWithAuth(
        `VehicleJobItems/GetPastJobsByVMRSCategorySubCategory/${jobId}?VehicleID=${VehicleId}&VMRSCategoryID=${VMRSCatId}&VMRSSubCategoryID=${VMRSSubCatId}`
    );
    return res;
};

export const GetPurchaseInvoice = async (id) => {
    let res = await getWithAuth(`Invoices/GetPurchaseInvoiceLines?id=${id}`);

    return res;
};

export const GetSalesInvoice = async (id) => {
    let res = await getWithAuth(`Invoices/GetSalesInvoiceLines?id=${id}`);

    return res;
};

export const SalesInvoiceAddHeaderLabourPart = async (data) => {
    let res = await postWithAuth(`Invoices/SalesInvoiceAddHeaderLabourPart`, data);

    return res;
};
export const GetVehicleSalesInvoice = async (id) => {
    let res = await getWithAuth(`Invoices/VehicleSalesInvoice?id=${id}`);
    return res;
};
export const UpdateVehicleSalesInvoice = async (data) => {
    let res = await postWithAuth(`Invoices/UpdateVehicleSalesInvoice`, data);

    return res;
};
export const PostInternalVehicleJobsItem = async (data) => {
    let res = await postWithAuth(`VehicleJobItems/InternalVehicleJobsItem`, data);
    return res;
};
export const VehicleSalesInvoiceGenratePdf = async (data) => {
    let res = await getWithAuth(`Invoices/VehicleSalesInvoiceGenratePdf?Id=${data}`, { Id: data });
    return res;
};
